import ImageBig from "../Projects/Image/ImageBig";
import imageBigFirst from "../../images/about-big-first.jpg";
import imageIndustriesFirst from "../../images/acme-about-first.jpg";
import imageIndustriesSecond from "../../images/sikka-about-first.jpg";
import imageIndustriesThird from "../../images/space-about-first.jpg";
import imageIndustriesFourth from "../../images/doto-about-first.jpg";
import imageIndustriesFifth from "../../images/springbox-about-first.jpg";
import imageIndustriesSixth from "../../images/dataspike-about-first.jpg";
import imageIndustriesSeventh from "../../images/finangel-about-first.jpg";
import imageIndustriesEighth from "../../images/pleh-about-first.jpg";
import imageIndustriesNinth from "../../images/assist-about-first.jpg";
import imageIndustriesTenth from "../../images/dataspike-about-second.jpg";
import fotoAlexa from "../../images/foto-alexa.jpg";
import fotoJulia from "../../images/foto-julia.jpg";
import fotoLiza from "../../images/foto-liza.jpg";
import fotoAnna from "../../images/foto-anna.jpg";
import fotoEkaterina from "../../images/foto-ekaterina.jpg";
import fotoSasha from "../../images/foto-sasha.jpg";
import fotoNikita from "../../images/foto-nikita.jpg";
import fotoKsenia from "../../images/foto-ksenia.jpg";
import advantagesIconFirst from "../../images/advantages-icon-first.svg";
import advantagesIconSecond from "../../images/advantages-icon-second.svg";
import advantagesIconThird from "../../images/advantages-icon-third.svg";
import advantagesIconFourth from "../../images/advantages-icon-fourth.svg";
import clientsSpace from "../../images/clients-space.jpg";
import clientsAnkr from "../../images/clients-ankr.jpg";
import clientsAssist from "../../images/clients-assist.jpg";
import clientsDataspike from "../../images/clients-dataspike.jpg";
import clientsMobalytics from "../../images/clients-mobalytics.jpg";
import clientsFinangel from "../../images/clients-finangel.jpg";
import clientsDoto from "../../images/clients-doto.jpg";
import clientsSpringbox from "../../images/clients-springbox.jpg";
import clientsDefinite from "../../images/clients-definite.jpg";
import clientsAcme from "../../images/clients-acme.jpg";
import reviewsFotoFirst from "../../images/reviews-foto-first.jpg";
import reviewsFotoSecond from "../../images/reviews-foto-second.jpg";
import reviewsFotoThird from "../../images/reviews-foto-third.jpg";
import reviewsFotoFourth from "../../images/reviews-foto-fourth.jpg";


function About(props) {
    function openProjects() {
        props.history.push('/projects');
    }

    function openAcme() {
        props.history.push('/projects/acme');
    }

    function openSikka() {
        props.history.push('/projects/sikka');
    }

    function openSpace() {
        props.history.push('/projects/space307');
    }

    function openDoto() {
        props.history.push('/projects/doto');
    }

    function openSpringbox() {
        props.history.push('/projects/springbox');
    }

    function openDataspike() {
        props.history.push('/projects/dataspike');
    }

    function openFinangel() {
        props.history.push('/projects/finangel');
    }

    function openPleh() {
        props.history.push('/projects/pleh');
    }

    function openAssist() {
        props.history.push('/projects/assistalong');
    }

    return (
        <div className="about" >
            <section className="about__main-screen">
                <div className="about__title-container">
                    <div className="about__string about__string_type_first">
                        <h1 className="about__title about__title_type_purple">Make It WOW –</h1>
                    </div>
                    <div className="about__string about__string_type_second">
                        <h2 className="about__title">the goal we set for</h2>
                    </div>
                    <div className="about__string about__string_type_second">
                        <h2 className="about__title">every project</h2>
                    </div>
                </div>
                <div className="about__text-container">
                    <p className="about__text">Make It WOW is distinguished by invaluable experience in the Fintech field. Throughout our journey, we have designed hundreds of projects including banking apps, trading platforms, crypto, DeFi and Web3 solutions. This allows us to immediately dive into the details of the project and speak the same language with our clients.</p>
                    <p className="about__text">We believe in the success of our customers' projects and the power of great design. Even if our client has a simple sketch or a rough idea, we possess the expertise and creativity to bring it to life and help him achieve amazing results.</p>
                </div>
            </section>
            <ImageBig
                padding={false}
                image={imageBigFirst}
                alt={'Make It WOW'}
            />
            <section className="miw">
                <div className="miw__content">
                    <div className="miw__data-container">
                        <div className="miw__data">
                            <p className="miw__text">15+</p>
                            <p className="miw__text_type_small">years of experience</p>
                        </div>
                        <div className="miw__data">
                            <p className="miw__text">300</p>
                            <p className="miw__text_type_small">completed projects</p>
                        </div>
                        <div className="miw__data">
                            <p className="miw__text">25M</p>
                            <p className="miw__text_type_small">monthly active users</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="industries">
                <div className="industries__title-container">
                    <h2 className="industries__title">Why should you choose us?</h2>
                    <button className="latest-projects__link" onClick={openProjects}>
                        <p className="latest-projects__link_type_text">See all our services</p>
                        <svg className="latest-projects__link_type_arrow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M9.29289 3.29289C9.68342 2.90237 10.3166 2.90237 10.7071 3.29289L14.7071 7.29289C15.0976 7.68342 15.0976 8.31658 14.7071 8.70711L10.7071 12.7071C10.3166 13.0976 9.68342 13.0976 9.29289 12.7071C8.90237 12.3166 8.90237 11.6834 9.29289 11.2929L11.5858 9H2C1.44772 9 1 8.55228 1 8C1 7.44772 1.44772 7 2 7H11.5858L9.29289 4.70711C8.90237 4.31658 8.90237 3.68342 9.29289 3.29289Z"/>
                        </svg>
                    </button>
                </div>
                <div className="industries__list">
                    <div className="industries__field">
                        <div className="industries__field-text-container">
                            <p className="industries__field-title">Crypto</p>
                            <p className="industries__field-text">Make it WOW also specializes in cryptocurrency projects. Our designs captivate users at a glance, instilling trust in crypto projects from the very first moment. We also implement convenient Design Systems and UI KITs to ensure consistency in design and help clients achieve the necessary results easier.</p>
                        </div>
                        <div className="industries__images">
                            <div className="industries__image-container" onClick={openAcme}>
                                <img className="industries__image" src={imageIndustriesFirst} alt="Acme"></img>
                                <div className="industries__image-text-container">
                                    <p className="industries__image-name">Acme</p>
                                    <p className="industries__image-text">1-click crypto checkout App & Website</p>
                                </div>
                            </div>
                            <div className="industries__image-container" onClick={openSikka}>
                                <img className="industries__image" src={imageIndustriesSecond} alt="Sikka"></img>
                                <div className="industries__image-text-container">
                                    <p className="industries__image-name">Sikka</p>
                                    <p className="industries__image-text">Lending Protocol For A Stablecoin</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="industries__field">
                        <div className="industries__field-text-container">
                            <p className="industries__field-title">Trading</p>
                            <p className="industries__field-text">We understand that trading tools can often be complex and saturated for users. Our mission is to simplify and enhance the trading experience, making it more user-friendly and accessible. With this idea we have successfully designed numerous trading projects, including Forex, CFD, Stocks, etc.</p>
                        </div>
                        <div className="industries__images">
                            <div className="industries__image-container" onClick={openSpace}>
                                <img className="industries__image" src={imageIndustriesThird} alt="space307"></img>
                                <div className="industries__image-text-container">
                                    <p className="industries__image-name">space307</p>
                                    <p className="industries__image-text">Trading Platform App: Desktop, Mobile Web, Android, iOS</p>
                                </div>
                            </div>
                            <div className="industries__image-container" onClick={openDoto}>
                                <img className="industries__image" src={imageIndustriesFourth} alt="Doto"></img>
                                <div className="industries__image-text-container">
                                    <p className="industries__image-name">Doto</p>
                                    <p className="industries__image-text">Forex Trading Platform Website</p>
                                </div>
                            </div>
                            <div className="industries__image-container" onClick={openSpringbox}>
                                <img className="industries__image" src={imageIndustriesFifth} alt="Springbox AI"></img>
                                <div className="industries__image-text-container">
                                    <p className="industries__image-name">Springbox AI</p>
                                    <p className="industries__image-text">Trading Platform: App Design, Web Design, UI Kit and Indicators Interface</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="industries__field">
                        <div className="industries__field-text-container">
                            <p className="industries__field-title">Banking</p>
                            <p className="industries__field-text">Our team specializes in designing cutting-edge platforms and applications tailored for the banking sector. We excel in designing user-friendly online banking solutions, as well as captivating websites and landing pages specifically designed for banks. Our goal is to design banking applications in which users can conveniently manage their finances, feel confident and safe.</p>
                        </div>
                        <div className="industries__images">
                            <div className="industries__image-container" onClick={openDataspike}>
                                <img className="industries__image" src={imageIndustriesSixth} alt="Dataspike"></img>
                                <div className="industries__image-text-container">
                                    <p className="industries__image-name">Dataspike</p>
                                    <p className="industries__image-text">Anti-Money Laundering Compliance Scanner</p>
                                </div>
                            </div>
                            <div className="industries__image-container" onClick={openFinangel}>
                                <img className="industries__image" src={imageIndustriesSeventh} alt="Finangel"></img>
                                <div className="industries__image-text-container">
                                    <p className="industries__image-name">Finangel</p>
                                    <p className="industries__image-text">Banking. Partners admin panel</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="industries__field">
                        <div className="industries__field-text-container">
                            <p className="industries__field-title">Artificial intelligence</p>
                            <p className="industries__field-text">As artificial intelligence continues to integrate into our daily lives, we are approached by clients seeking modern and effective design for their AI-based projects. We have already created several bright platforms and applications in this area that reflect the essence of AI and innovative ideas of our clients.</p>
                        </div>
                        <div className="industries__images">
                            <div className="industries__image-container" onClick={openSpringbox}>
                                <img className="industries__image" src={imageIndustriesFifth} alt="Springbox AI"></img>
                                <div className="industries__image-text-container">
                                    <p className="industries__image-name">Springbox AI</p>
                                    <p className="industries__image-text">Trading Platform: App Design, Web Design, UI Kit and Indicators Interface</p>
                                </div>
                            </div>
                            <div className="industries__image-container" onClick={openPleh}>
                                <img className="industries__image" src={imageIndustriesEighth} alt="pleH"></img>
                                <div className="industries__image-text-container">
                                    <p className="industries__image-name">pleH</p>
                                    <p className="industries__image-text">Innovative educational platform powered by AI for students and teachers worldwide</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="industries__field">
                        <div className="industries__field-text-container">
                            <p className="industries__field-title">Marketing</p>
                            <p className="industries__field-text">We know how to attract customers with a pleasant and convenient design. Therefore, we are ready to help you with a creation of marketing materials. Whether you need help with booklets design, presentations, or other printed assets, we're here to assist you. We create designs that not only look great but also help you achieve your business objectives by effectively communicating your message to your target audience.</p>
                        </div>
                        <div className="industries__images">
                            <div className="industries__image-container" onClick={openAssist}>
                                <img className="industries__image" src={imageIndustriesNinth} alt="Assist Along"></img>
                                <div className="industries__image-text-container">
                                    <p className="industries__image-name">Assist Along</p>
                                    <p className="industries__image-text">E-Commerce Subscription Service</p>
                                </div>
                            </div>
                            <div className="industries__image-container" onClick={openDataspike}>
                                <img className="industries__image" src={imageIndustriesTenth} alt="Dataspike"></img>
                                <div className="industries__image-text-container">
                                    <p className="industries__image-name">Dataspike</p>
                                    <p className="industries__image-text">Anti-Money Laundering Compliance Scanner</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="team">
                <div className="team__content">
                    <h3 className="team__title">our team</h3>
                    <div className="team__info">
                        <p className="team__heading">We believe in the power of great design</p>
                        <p className="team__text">Based in Dubai, our dynamic team of professionals consists of designers and developers who carry out projects all over the world. Passion and interest are the driving forces behind everything we do. We strive to create user experiences that are clear, intuitive, anticipatory and captivating, so that your audience keeps coming back for more.</p>
                    </div>
                    <div className="team__photos">
                        <div className="team__photo-container">
                            <img className="team__photo" src={fotoAlexa} alt="CEO & Lead Designer"></img>
                            <div className="team__name-container">
                                <p className="team__name">Alexa Vdovydchenko</p>
                                <p className="team__name team__name_type_color-text">CEO & Lead Designer</p>
                            </div>
                        </div>
                        <div className="team__photo-container">
                            <img className="team__photo" src={fotoJulia} alt="Product Designer"></img>
                            <div className="team__name-container">
                                <p className="team__name">Julia Izmailova</p>
                                <p className="team__name team__name_type_color-text">Product Designer</p>
                            </div>
                        </div>
                        <div className="team__photo-container">
                            <img className="team__photo" src={fotoLiza} alt="Product Designer"></img>
                            <div className="team__name-container">
                                <p className="team__name">Elizaveta Peretolchina</p>
                                <p className="team__name team__name_type_color-text">Product Designer</p>
                            </div>
                        </div>
                        <div className="team__photo-container">
                            <img className="team__photo" src={fotoAnna} alt="Product Designer"></img>
                            <div className="team__name-container">
                                <p className="team__name">Anna Deryugina</p>
                                <p className="team__name team__name_type_color-text">Product Designer</p>
                            </div>
                        </div>
                        <div className="team__photo-container">
                            <img className="team__photo" src={fotoEkaterina} alt="Product Designer"></img>
                            <div className="team__name-container">
                                <p className="team__name">Ekaterina Orlova</p>
                                <p className="team__name team__name_type_color-text">Product Designer</p>
                            </div>
                        </div>
                        <div className="team__photo-container">
                            <img className="team__photo" src={fotoSasha} alt="Product Designer"></img>
                            <div className="team__name-container">
                                <p className="team__name">Sasha Larieva</p>
                                <p className="team__name team__name_type_color-text">Product Designer</p>
                            </div>
                        </div>
                        <div className="team__photo-container">
                            <img className="team__photo" src={fotoNikita} alt="Front-end developer"></img>
                            <div className="team__name-container">
                                <p className="team__name">Nikita Vdovydchenko</p>
                                <p className="team__name team__name_type_color-text">Front-end developer</p>
                            </div>
                        </div>
                        <div className="team__photo-container">
                            <img className="team__photo" src={fotoKsenia} alt="Business Development Manager"></img>
                            <div className="team__name-container">
                                <p className="team__name">Ksenia Ziserman</p>
                                <p className="team__name team__name_type_color-text">Business Development Manager</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="advantages">
                <div className="advantages__content">
                    <h3 className="advantages__title">advantages</h3>
                    <div className="advantages__container">
                        <div className="advantages__card">
                            <img className="advantages__icon" src={advantagesIconFirst} alt="Creative Thinking"></img>
                            <p className="advantages__heading">Creative Thinking</p>
                            <p className="advantages__text">Our team can showcase multiple design concepts for your project, providing you with a range of options to choose from.</p>
                        </div>
                        <div className="advantages__card">
                            <img className="advantages__icon"src={advantagesIconSecond} alt="Fast & High Quality"></img>
                            <p className="advantages__heading">Fast & High Quality</p>
                            <p className="advantages__text">We understand the importance of meeting deadlines and are committed to providing you with a solution that exceeds your expectations, even within accelerated timelines.</p>
                        </div>
                        <div className="advantages__card">
                            <img className="advantages__icon" src={advantagesIconThird} alt="Dedicated & Flexible Team"></img>
                            <p className="advantages__heading">Dedicated & Flexible Team</p>
                            <p className="advantages__text">We are fully dedicated to the project, actively listening to our clients. We are flexible and quick to respond to changes, ensuring we deliver the best results.</p>
                        </div>
                        <div className="advantages__card">
                            <img className="advantages__icon" src={advantagesIconFourth} alt="Long-term Relationships"></img>
                            <p className="advantages__heading">Long-term Relationships</p>
                            <p className="advantages__text">We value the establishment of long-term relationships with our clients and within our team.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="clients">
                <h3 className="clients__title">Shout-out to our clients</h3>
                <div className="clients__container">
                    <div className="clients__icon-container">
                        <img className="clients__icon" src={clientsSpace} alt="space307"></img>
                    </div>
                    <div className="clients__icon-container">
                        <img className="clients__icon" src={clientsAnkr} alt="Ankr"></img>
                    </div>
                    <div className="clients__icon-container">
                        <img className="clients__icon" src={clientsAssist} alt="Assist Along"></img>
                    </div>
                    <div className="clients__icon-container">
                        <img className="clients__icon" src={clientsDataspike} alt="Dataspike"></img>
                    </div>
                    <div className="clients__icon-container">
                        <img className="clients__icon" src={clientsMobalytics} alt="Mobalytics"></img>
                    </div>
                    <div className="clients__icon-container">
                        <img className="clients__icon" src={clientsFinangel} alt="Finangel"></img>
                    </div>
                    <div className="clients__icon-container">
                        <img className="clients__icon" src={clientsDoto} alt="Doto"></img>
                    </div>
                    <div className="clients__icon-container">
                        <img className="clients__icon" src={clientsSpringbox} alt="Springbox AI"></img>
                    </div>
                    <div className="clients__icon-container">
                        <img className="clients__icon" src={clientsDefinite} alt="Definite"></img>
                    </div>
                    <div className="clients__icon-container">
                        <img className="clients__icon" src={clientsAcme} alt="Acme"></img>
                    </div>
                </div>
            </section>
            <section className="reviews">
                <div className="reviews__container">
                    <div className="reviews__info">
                        <img className="reviews__photo" src={reviewsFotoFirst} alt="CEO at Acme"></img>
                        <div className="reviews__name-container">
                            <p className="reviews__name">Nilesh Khaitan</p>
                            <p className="reviews__job-title">CEO at Acme</p>
                        </div>
                    </div>
                    <div className="reviews__text-container">
                        <p className="reviews__heading">Working with Make it WOW has been an absolute pleasure. Their designs are consistently creative, clean, and elegantly simple</p>
                        <p className="reviews__text">One of the things that really stands out is their ability to deliver results quickly, even when faced with tight deadlines. They have gone above and beyond to refine our work and ensure that we are completely satisfied with the final product. Their dedication and attention to detail have been instrumental in building the easiest and safest crypto experience in the industry. Grateful to be working with them, and I highly recommend them as a talented UI design team.</p>
                    </div>
                </div>
                <div className="reviews__container">
                    <div className="reviews__info">
                        <img className="reviews__photo" src={reviewsFotoSecond} alt="Сo-owner of Knuff & Company"></img>
                        <div className="reviews__name-container">
                            <p className="reviews__name">William G. Knuff, III</p>
                            <p className="reviews__job-title">Сo-owner of Knuff & Company</p>
                        </div>
                    </div>
                    <div className="reviews__text-container">
                        <p className="reviews__heading">Regarding feedback about working together on this project, I thought Make it WOW team work product, professionalism, creativity, and communication skills were excellent</p>
                        <p className="reviews__text">The positive attitude made working with you, especially remotely, a pleasure. Thank you for the quality work. I appreciated team efforts and enjoyed collaborating with you. If I have a project referral for Make it WOW, I will recommend the team for UI/UX design services and facilitate an introduction to owners.</p>
                    </div>
                </div>
                <div className="reviews__container">
                    <div className="reviews__info">
                        <img className="reviews__photo" src={reviewsFotoThird} alt="Head of Product at DataSpike"></img>
                        <div className="reviews__name-container">
                            <p className="reviews__name">Alex Petuchov</p>
                            <p className="reviews__job-title">Head of Product at DataSpike</p>
                        </div>
                    </div>
                    <div className="reviews__text-container">
                        <p className="reviews__heading">Make it WOW has exceeded all our expectations</p>
                        <p className="reviews__text">Their expertise in UI/UX design and unwavering dedication to Dataspike KYC SaaS platform have truly exceeded our expectations. From creating captivating landing pages to designing seamless flows within our dashboard, Make it WOW’s attention to detail and creative prowess have elevated our user experience to new heights. Their contributions, including the design system, icons, and corporate materials, have strengthened our brand identity and left a lasting impact on our clients. We are grateful to have had the opportunity to collaborate with Make it WOW, and we highly recommend this agency of talented and professional UI/UX Designers.</p>
                    </div>
                </div>
                <div className="reviews__container">
                    <div className="reviews__info">
                        <img className="reviews__photo" src={reviewsFotoFourth} alt="CEO at Finangel"></img>
                        <div className="reviews__name-container">
                            <p className="reviews__name">Dmitriy Zaretskiy</p>
                            <p className="reviews__job-title">CEO at Finangel</p>
                        </div>
                    </div>
                    <div className="reviews__text-container">
                        <p className="reviews__heading">I am extremely satisfied with my collaboration with Make it WOW. The team demonstrated exceptional problem-solving skills and offered innovative ideas</p>
                        <p className="reviews__text">The team was able to successfully implemented high-quality solutions, tailored to different levels of complexity in our mobile app and web interfaces for our Financial Banking Product. They introduced new features that enhanced user engagement and improved the functionality of our Financial Banking App. Through our partnership, we have created a range of outstanding software products that have elevated the level of design within our company. I am grateful for the opportunity to work alongside this agency and look forward to embarking on more projects together in the future.</p>
                    </div>
                </div>
            </section>
            <section className="check">
                <button className="check__button" onClick={openProjects}>check our projects</button>
            </section>
        </div>
    );
}

export default About;