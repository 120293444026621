import Introduction from "../Introduction/Introduction";
import HeadingMedium from "../Heading/HeadingMedium";
import ImageMedium from "../Image/ImageMedium";
import ImageBig from "../Image/ImageBig";
import NextCase from "../NextCase";
import imageSmallFirst from "../../../images/acme-small-first.jpg";
import imageSmallSecond from "../../../images/acme-small-second.jpg";
import imageSmallThird from "../../../images/acme-small-third.jpg";
import imageSmallFourth from "../../../images/acme-small-fourth.jpg";
import imageSmallFifth from "../../../images/acme-small-fifth.jpg";
import imageSmallSixth from "../../../images/acme-small-sixth.jpg";
import imageMediumFirst from "../../../images/acme-medium-first.jpg";
import imageMediumSecond from "../../../images/acme-medium-second.jpg";
import imageMediumThird from "../../../images/acme-medium-third.jpg";
import imageBigFirst from "../../../images/acme-big-first.jpg";
import imageBigSecond from "../../../images/acme-big-second.jpg";
import imageBigThird from "../../../images/acme-big-third.jpg";

function Acme(props) {
    return (
        <div className="acme">
            <Introduction
                name={'Acme'}
                title={'1-click crypto checkout'}
                image={imageBigFirst}
                alt={'Acme'}
            />
            <HeadingMedium
                title={'The Client'}
                text={'A Web 3 DApps project (NFT marketplaces, DeFi Protocols, Metaverse experiences, etc.) who have deployed Smart Contracts on public blockchains.'}
            />
            <ImageMedium
                padding={false}
                image={imageMediumFirst}
                alt={'Acme'}
            />
            <ImageBig
                padding={true}
                image={imageBigSecond}
                alt={'Acme'}
            />
            <div className="heading heading_type_none-padding-bottom">
                <h3 className="heading__title_type_big">the result</h3>
            </div>
            <HeadingMedium
                title={'Buy NFT Flow'}
                text={'Easily execute & sharean on-chain transaction (Buy NFTs, List NFTs for sale, Create NFT), complete KYC, purchase the required crypto using fiat accounts, sign transaction, review progress & share.'}
            />
            <ImageMedium
                padding={true}
                image={imageMediumSecond}
                alt={'Acme'}
            />
            <ImageMedium
                padding={false}
                image={imageMediumThird}
                alt={'Acme'}
            />
            <HeadingMedium
                title={'Inner Pages'}
                text={`Low-level pages are often neglected in terms of design. That's never the case with Make it WOW.`}
            />
            <div className="image-with-text">
                <div className="image-with-text__couple">
                    <div className="image-with-text__container">
                        <img className="image-with-text__image" src={imageSmallFirst} alt='Acme'></img>
                        <p className="image-with-text__text">User can choose different type os SSO or start with mobile phone number or email</p>
                    </div>
                    <div className="image-with-text__container">
                        <img className="image-with-text__image" src={imageSmallSecond} alt='Acme'></img>
                        <p className="image-with-text__text">KYC takes only 3 steps: select document, take a photo and take a selfie.</p>
                    </div>
                    <div className="image-with-text__container">
                        <img className="image-with-text__image" src={imageSmallThird} alt='Acme'></img>
                        <p className="image-with-text__text">Share your purchased NFT with friends via different platforms such as Twitter, Facebook, Instagram, TikTok</p>
                    </div>
                </div>
                <div className="image-with-text__couple image-with-text__couple_type_margin">
                    <div className="image-with-text__container">
                        <img className="image-with-text__image" src={imageSmallFourth} alt='Acme'></img>
                        <p className="image-with-text__text">User can pay in 1 Click using Apple or Google Pay. Also he can pay via bank card.</p>
                    </div>
                    <div className="image-with-text__container">
                        <img className="image-with-text__image" src={imageSmallFifth} alt='Acme'></img>
                        <p className="image-with-text__text">Use Acme as personal wallet or funds saver. All your Crypto & NFT’s will be also shown there.</p>
                    </div>
                    <div className="image-with-text__container">
                        <img className="image-with-text__image" src={imageSmallSixth} alt='Acme'></img>
                        <p className="image-with-text__text">Split the payment amount between your accounts</p>
                    </div>
                </div>
            </div>
            <ImageBig
                padding={true}
                image={imageBigThird}
                alt={'Acme'}
            />
            <NextCase
                history={props.history}
                link={'/projects/definite'}
            />
        </div>
    );
}

export default Acme;