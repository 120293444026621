function Menu(props) {
    function openProjects() {
        props.history.push('/projects');
        props.onClose();
    }

    function openAbout() {
        props.history.push('/about');
        props.onClose();
    }

    function openServices() {
        props.history.push('/services');
        props.onClose();
    }

    function openContacts() {
        props.history.push('/contacts');
        props.onClose();
    }

    function openPitchDeck() {
        props.history.push('/pitch-deck');
        props.onClose();
    }

    function openContactUs() {
        props.history.push('/contact-us');
        props.onClose();
    }

    return (
        <div className={`menu__content ${!props.isOpen ? 'menu_is-closed' : ''}`}>
            <div className="menu__container">
                <ul className="menu__list">
                    <p className="menu__heading">Menu</p>
                    <li><button className="menu__link" onClick={openProjects}>Projects</button></li>
                    <li><button className="menu__link" onClick={openAbout}>About</button></li>
                    <li><button className="menu__link" onClick={openServices}>Services</button></li>
                    {/* <li><button className="menu__link" onClick={openContacts}>Contacts</button></li> */}
                    <li><button className="menu__link" onClick={openPitchDeck}>Pitch Deck</button></li>
                    <li><button className="menu__link" onClick={openContactUs}>Contact Us</button></li>
                </ul>
                <div className="menu__email-container">
                    <p className="menu__heading">Get in touch</p>
                    <button className="menu__email" onClick={props.openMail}>hi@makeitwow.io</button>
                </div>
                <button className="menu__close" onClick={props.onClose}></button>
            </div>
        </div>
    );
}
export default Menu;