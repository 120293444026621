import Service from "./Service";

function ListServices() {
    return (
        <div className="list-services">
            <div className="list-services__content">
                <h2 className="list-services__title">Services</h2>
                <div className="list-services__list">
                    <Service
                        serviceId={'service-websites'}
                        id={'websites'}
                        htmlFor={'websites'}
                        headingClass={''}
                        textClass={''}
                        heading={'Websites'}
                        text={`We design high quality websites that make users come back for more. A good website tells a story that will make users fully immerse themselves in it.`}
                    />
                    <Service
                        serviceId={'service-platforms'}
                        id={'platforms'}
                        htmlFor={'platforms'}
                        headingClass={''}
                        textClass={''}
                        heading={'Platforms'}
                        text={`We develop platforms for fintech projects of any complexity. We have 8 years of experience in creating full operating Forex and Crypto projects just from scratch. It is important for us to design interfaces that are intuitive and easy for users to navigate in.`}
                    />
                    <Service
                        serviceId={'service-mobile-apps'}
                        id={'mobile-apps'}
                        htmlFor={'mobile-apps'}
                        headingClass={''}
                        textClass={''}
                        heading={'Mobile Apps'}
                        text={`Whether you're looking to create a web app or a mobile app – we got you covered. We have a proven track record of building both iOS and Android applications that are perfectly suited for your users needs.`}
                    />
                    <Service
                        serviceId={'service-strategy-and-branding'}
                        id={'strategy-and-branding'}
                        htmlFor={'strategy-and-branding'}
                        headingClass={''}
                        textClass={''}
                        heading={'Strategy & Branding'}
                        text={`Understanding the needs of our clients is our top priority. That is why we fully explore your ideas and business to create a unique product in the market. We develop logos, corporate identity, user manuals, any mockups, and souvenir products that can help your brand stand out.`}
                    />
                </div>
            </div>
        </div>
    );
}

export default ListServices;