import React from "react";
import CoupleOfProjects from "./CoupleOfProjects";
import starfall from "../../images/starfall.jpg";
import gami from "../../images/gami.jpg";
import pleh from "../../images/pleh.jpg";
import dataspike from "../../images/dataspike.png";
import space from "../../images/space307.jpg";
import springbox from "../../images/springbox-ai.jpg";
import acme from "../../images/acme.jpg";
import definite from "../../images/definite.jpg";
import doto from "../../images/doto.jpg";
import assist from "../../images/assist-along.jpg";
import sikka from "../../images/sikka.jpg";
import finangel from "../../images/finangel.jpg";

function Projects(props) {
    return (
        <>
            <div className="description">
                <div className="description__content">
                    <h1 className="description__title">All projects</h1>
                    <p className="description__text">Over the years we've built up unique expertise in developing user stories and design solutions specifically for Fintech. Our experience in designing banking apps, trading platforms, crypto, DeFi, and Web3 solutions gives us a vantage point other design agencies are not privy to.</p>
                </div>
            </div>
            <div className="all-projects">
                <CoupleOfProjects
                    history={props.history}
                    nameFirst={'Starfall'}
                    headingFirst={'New-generation Web3 Moba Game'}
                    imageFirst={starfall}
                    linkFirst={'/projects/starfall'}
                    nameSecond={'Gami Trading'}
                    headingSecond={'Trading Platform App: Desktop, Mobile Web, Android, iOS'}
                    imageSecond={gami}
                    linkSecond={'/projects/gami-trading'}
                />
                <CoupleOfProjects
                    history={props.history}
                    nameFirst={'pleH'}
                    headingFirst={'Innovative educational platform powered by AI for students and teachers worldwide'}
                    imageFirst={pleh}
                    linkFirst={'/projects/pleh'}
                    nameSecond={'Dataspike'}
                    headingSecond={'Anti-Money Laundering Compliance Scanner'}
                    imageSecond={dataspike}
                    linkSecond={'/projects/dataspike'}
                />
                <CoupleOfProjects
                    history={props.history}
                    nameFirst={'space307'}
                    headingFirst={'Trading Platform App: Desktop, Mobile Web, Android, iOS'}
                    imageFirst={space}
                    linkFirst={'/projects/space307'}
                    nameSecond={'Springbox AI'}
                    headingSecond={'AI-driven signals. App Design, Web Design, Indicators Interface'}
                    imageSecond={springbox}
                    linkSecond={'/projects/springbox'}
                />
                <CoupleOfProjects
                    history={props.history}
                    nameFirst={'Acme'}
                    headingFirst={'1-click crypto checkout App & Website'}
                    imageFirst={acme}
                    linkFirst={'/projects/acme'}
                    nameSecond={'Definite'}
                    headingSecond={'Decentralized (DeFi) Lending Platform'}
                    imageSecond={definite}
                    linkSecond={'/projects/definite'}
                />
                <CoupleOfProjects
                    history={props.history}
                    nameFirst={'Doto'}
                    headingFirst={'Forex Trading Platform Website'}
                    imageFirst={doto}
                    linkFirst={'/projects/doto'}
                    nameSecond={'Assist Along'}
                    headingSecond={'E-Commerce Subscription Service'}
                    imageSecond={assist}
                    linkSecond={'/projects/assistalong'}
                />
                <CoupleOfProjects
                    history={props.history}
                    nameFirst={'Sikka'}
                    headingFirst={'Lending Protocol For A Stablecoin'}
                    imageFirst={sikka}
                    linkFirst={'/projects/sikka'}
                    nameSecond={'Finangel'}
                    headingSecond={'Banking. Partners admin panel'}
                    imageSecond={finangel}
                    linkSecond={'/projects/finangel'}
                />
            </div>
        </>
    );
}

export default Projects;