import ImageBig from "../Projects/Image/ImageBig";
import imageBigFirst from "../../images/services-big-first.jpg";
import imageIndustriesFirst from "../../images/dataspike-services-first.jpg";
import imageIndustriesSecond from "../../images/doto-about-first.jpg";
import imageIndustriesThird from "../../images/assist-services-first.jpg";
import imageIndustriesFourth from "../../images/pleh-services-first.jpg";
import imageIndustriesFifth from "../../images/dataspike-services-second.jpg";
import imageIndustriesSixth from "../../images/space-services-first.jpg";
import imageIndustriesSeventh from "../../images/acme-about-first.jpg";
import imageIndustriesEighth from "../../images/springbox-about-first.jpg";
import imageIndustriesNinth from "../../images/definite-services-first.jpg";
import imageIndustriesTenth from "../../images/pleh-services-second.jpg";
import clientsSpace from "../../images/clients-space.jpg";
import clientsAnkr from "../../images/clients-ankr.jpg";
import clientsAssist from "../../images/clients-assist.jpg";
import clientsDataspike from "../../images/clients-dataspike.jpg";
import clientsMobalytics from "../../images/clients-mobalytics.jpg";
import clientsFinangel from "../../images/clients-finangel.jpg";
import clientsDoto from "../../images/clients-doto.jpg";
import clientsSpringbox from "../../images/clients-springbox.jpg";
import clientsDefinite from "../../images/clients-definite.jpg";
import clientsAcme from "../../images/clients-acme.jpg";
import reviewsFotoFirst from "../../images/reviews-foto-first.jpg";
import reviewsFotoSecond from "../../images/reviews-foto-second.jpg";
import reviewsFotoThird from "../../images/reviews-foto-third.jpg";
import reviewsFotoFourth from "../../images/reviews-foto-fourth.jpg";

function Services(props) {
    function openProjects() {
        props.history.push('/projects');
    }

    function openDataspike() {
        props.history.push('/projects/dataspike');
    }

    function openDoto() {
        props.history.push('/projects/doto');
    }

    function openAssist() {
        props.history.push('/projects/assistalong');
    }

    function openPleh() {
        props.history.push('/projects/pleh');
    }

    function openSpace() {
        props.history.push('/projects/space307');
    }

    function openAcme() {
        props.history.push('/projects/acme');
    }

    function openSpringbox() {
        props.history.push('/projects/springbox');
    }

    function openDefinite() {
        props.history.push('/projects/definite');
    }

    return (
        <div className="services">
            <section className="about__main-screen">
                <div className="about__title-container">
                    <div className="about__string about__string_type_first">
                        <h2 className="about__title">we provide</h2>
                    </div>
                    <div className="services__string">
                        <h2 className="about__title">design</h2>
                        <h2 className="about__title about__title_type_purple">solutions</h2>
                    </div>
                    <div className="about__string about__string_type_second">
                        <h2 className="about__title">for fintech</h2>
                    </div>
                </div>
                <div className="about__text-container">
                    <p className="about__text">We provide design solutions for Fintech including banking, trading, forex, crypto, web3, and other industries. We help both large and small companies achieve goals to improve communication and increase sales.</p>
                    <p className="about__text">Depending on objectives, we will find a way to achieve them through design and create a new brand strategy, website, mobile application or platform meeting your needs.</p>
                </div>
            </section>
            <ImageBig
                padding={false}
                image={imageBigFirst}
                alt={'Make It WOW'}
            />
            <section className="services__industries">
                <div className="industries">
                    <h2 className="services__industries-title">What we Offer</h2>
                    <div className="industries__list">
                        <div className="industries__field services__industries-field">
                            <div className="industries__field-text-container">
                                <p className="industries__field-title services__industries-field-title">Wesbites</p>
                                <p className="industries__field-text">We understand the importance of a website in representing your company. Our focus is on creating both visually appealing and user-friendly websites that help you achieve your desired results. At each stage of the work on the website, we can give you advices about content (text, images, illustrations, animations) and present them in the best possible way.</p>
                            </div>
                            <div className="industries__images">
                                <div className="industries__image-container" onClick={openDataspike}>
                                    <img className="industries__image" src={imageIndustriesFirst} alt="Dataspike"></img>
                                    <div className="industries__image-text-container">
                                        <p className="industries__image-name">Dataspike</p>
                                        <p className="industries__image-text services__industries-image-text">Anti-Money Laundering Compliance Scanner</p>
                                    </div>
                                </div>
                                <div className="industries__image-container" onClick={openDoto}>
                                    <img className="industries__image" src={imageIndustriesSecond} alt="Doto"></img>
                                    <div className="industries__image-text-container">
                                        <p className="industries__image-name">Doto</p>
                                        <p className="industries__image-text services__industries-image-text">Forex Trading Platform Website</p>
                                    </div>
                                </div>
                                <div className="industries__image-container" onClick={openAssist}>
                                    <img className="industries__image" src={imageIndustriesThird} alt="Springbox AI"></img>
                                    <div className="industries__image-text-container">
                                        <p className="industries__image-name">Assist Along</p>
                                        <p className="industries__image-text services__industries-image-text">E-Commerce Subscription Service</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="industries__field services__industries-field">
                            <div className="industries__field-text-container">
                                <p className="industries__field-title services__industries-field-title">Platforms</p>
                                <p className="industries__field-text">We have extensive experience in product design. When developing complex fintech applications or platforms, we prioritise user-centric design. Our goal is to create products that intuitively resonate with users, ensuring a seamless and effortless user experience. By deeply understanding the user's perspective, we aim to build applications that feel so natural and intuitive that users won't have a single question about how to use them.</p>
                            </div>
                            <div className="industries__images">
                                <div className="industries__image-container" onClick={openPleh}>
                                    <img className="industries__image" src={imageIndustriesFourth} alt="pleH"></img>
                                    <div className="industries__image-text-container">
                                        <p className="industries__image-name">pleH</p>
                                        <p className="industries__image-text services__industries-image-text">Innovative educational platform powered by AI for students and teachers worldwide</p>
                                    </div>
                                </div>
                                <div className="industries__image-container" onClick={openDataspike}>
                                    <img className="industries__image" src={imageIndustriesFifth} alt="Dataspike"></img>
                                    <div className="industries__image-text-container">
                                        <p className="industries__image-name">Dataspike</p>
                                        <p className="industries__image-text services__industries-image-text">Anti-Money Laundering Compliance Scanner</p>
                                    </div>
                                </div>
                                <div className="industries__image-container" onClick={openSpace}>
                                    <img className="industries__image" src={imageIndustriesSixth} alt="space307"></img>
                                    <div className="industries__image-text-container">
                                        <p className="industries__image-name">space307</p>
                                        <p className="industries__image-text services__industries-image-text">Trading Platform App: Desktop, Mobile Web, Android, iOS</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="industries__field services__industries-field">
                            <div className="industries__field-text-container">
                                <p className="industries__field-title services__industries-field-title">Mobile Apps</p>
                                <p className="industries__field-text">We are passionate about creating mobile apps of any complexity that look good on any device and operating system, whether it's Android or iOS. We start with a brief and target audience and create a clear user flow and prototype, which later we will design into hundreds of pixel perfect screens of your mobile app which generate high profit.</p>
                            </div>
                            <div className="industries__images">
                                <div className="industries__image-container" onClick={openAcme}>
                                    <img className="industries__image" src={imageIndustriesSeventh} alt="Acme"></img>
                                    <div className="industries__image-text-container">
                                        <p className="industries__image-name">Acme</p>
                                        <p className="industries__image-text services__industries-image-text">1-click crypto checkout App & Website</p>
                                    </div>
                                </div>
                                <div className="industries__image-container" onClick={openSpringbox}>
                                    <img className="industries__image" src={imageIndustriesEighth} alt="Springbox AI"></img>
                                    <div className="industries__image-text-container">
                                        <p className="industries__image-name">Springbox AI</p>
                                        <p className="industries__image-text services__industries-image-text">Trading Platform: App Design, Web Design, UI Kit and Indicators Interface</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="industries__field services__industries-field">
                            <div className="industries__field-text-container">
                                <p className="industries__field-title services__industries-field-title">Strategy & Branding</p>
                                <p className="industries__field-text">With a deep understanding of your brand's mission, values and vision, we are dedicated to creating a visual language that truly reflects the essence of your company. We create tailored branding kits that include logo design, colors, visual assets for social media, presentations, pitch decks, and more, helping you achieve your business goals. We work closely with each customer to discuss project goals and deadlines.</p>
                            </div>
                            <div className="industries__images">
                                <div className="industries__image-container" onClick={openDefinite}>
                                    <img className="industries__image" src={imageIndustriesNinth} alt="Definite"></img>
                                    <div className="industries__image-text-container">
                                        <p className="industries__image-name">Definite</p>
                                        <p className="industries__image-text services__industries-image-text">Decentralized (DeFi) Lending Platform</p>
                                    </div>
                                </div>
                                <div className="industries__image-container" onClick={openPleh}>
                                    <img className="industries__image" src={imageIndustriesTenth} alt="pleH"></img>
                                    <div className="industries__image-text-container">
                                        <p className="industries__image-name">pleH</p>
                                        <p className="industries__image-text services__industries-image-text">Innovative educational platform powered by AI for students and teachers worldwide</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="process">
                <div className="process__content">
                    <p className="team__title">our process</p>
                    <div className="process__entity-container">
                        <div className="process__entity process__entity_type_white">
                            <p className="process__entity-text process__entity-text_type_black">Research</p>
                        </div>
                        <div className="process__entity process__entity_type_green process__entity_type_padding-big">
                            <p className="process__entity-text process__entity-text_type_black">Design</p>
                        </div>
                        <div className="process__entity process__entity_type_black process__entity_type_padding-medium">
                            <p className="process__entity-text process__entity-text_type_white">Development</p>
                        </div>
                        <div className="process__entity process__entity_type_black">
                            <p className="process__entity-text process__entity-text_type_white">Testing</p>
                        </div>
                        <div className="process__entity process__entity_type_yellow">
                            <p className="process__entity-text process__entity-text_type_black">Release</p>
                        </div>
                        <div className="process__entity process__entity_type_white">
                            <p className="process__entity-text process__entity-text_type_black">Marketing</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="clients">
                <h3 className="clients__title">Shout-out to our clients</h3>
                <div className="clients__container">
                    <div className="clients__icon-container">
                        <img className="clients__icon" src={clientsSpace} alt="space307"></img>
                    </div>
                    <div className="clients__icon-container">
                        <img className="clients__icon" src={clientsAnkr} alt="Ankr"></img>
                    </div>
                    <div className="clients__icon-container">
                        <img className="clients__icon" src={clientsAssist} alt="Assist Along"></img>
                    </div>
                    <div className="clients__icon-container">
                        <img className="clients__icon" src={clientsDataspike} alt="Dataspike"></img>
                    </div>
                    <div className="clients__icon-container">
                        <img className="clients__icon" src={clientsMobalytics} alt="Mobalytics"></img>
                    </div>
                    <div className="clients__icon-container">
                        <img className="clients__icon" src={clientsFinangel} alt="Finangel"></img>
                    </div>
                    <div className="clients__icon-container">
                        <img className="clients__icon" src={clientsDoto} alt="Doto"></img>
                    </div>
                    <div className="clients__icon-container">
                        <img className="clients__icon" src={clientsSpringbox} alt="Springbox AI"></img>
                    </div>
                    <div className="clients__icon-container">
                        <img className="clients__icon" src={clientsDefinite} alt="Definite"></img>
                    </div>
                    <div className="clients__icon-container">
                        <img className="clients__icon" src={clientsAcme} alt="Acme"></img>
                    </div>
                </div>
            </section>
            <section className="reviews">
                <div className="reviews__container">
                    <div className="reviews__info">
                        <img className="reviews__photo" src={reviewsFotoFirst} alt="CEO at Acme"></img>
                        <div className="reviews__name-container">
                            <p className="reviews__name">Nilesh Khaitan</p>
                            <p className="reviews__job-title">CEO at Acme</p>
                        </div>
                    </div>
                    <div className="reviews__text-container">
                        <p className="reviews__heading">Working with Make it WOW has been an absolute pleasure. Their designs are consistently creative, clean, and elegantly simple</p>
                        <p className="reviews__text">One of the things that really stands out is their ability to deliver results quickly, even when faced with tight deadlines. They have gone above and beyond to refine our work and ensure that we are completely satisfied with the final product. Their dedication and attention to detail have been instrumental in building the easiest and safest crypto experience in the industry. Grateful to be working with them, and I highly recommend them as a talented UI design team.</p>
                    </div>
                </div>
                <div className="reviews__container">
                    <div className="reviews__info">
                        <img className="reviews__photo" src={reviewsFotoSecond} alt="Сo-owner of Knuff & Company"></img>
                        <div className="reviews__name-container">
                            <p className="reviews__name">William G. Knuff, III</p>
                            <p className="reviews__job-title">Сo-owner of Knuff & Company</p>
                        </div>
                    </div>
                    <div className="reviews__text-container">
                        <p className="reviews__heading">Regarding feedback about working together on this project, I thought Make it WOW team work product, professionalism, creativity, and communication skills were excellent</p>
                        <p className="reviews__text">The positive attitude made working with you, especially remotely, a pleasure. Thank you for the quality work. I appreciated team efforts and enjoyed collaborating with you. If I have a project referral for Make it WOW, I will recommend the team for UI/UX design services and facilitate an introduction to owners.</p>
                    </div>
                </div>
                <div className="reviews__container">
                    <div className="reviews__info">
                        <img className="reviews__photo" src={reviewsFotoThird} alt="Head of Product at DataSpike"></img>
                        <div className="reviews__name-container">
                            <p className="reviews__name">Alex Petuchov</p>
                            <p className="reviews__job-title">Head of Product at DataSpike</p>
                        </div>
                    </div>
                    <div className="reviews__text-container">
                        <p className="reviews__heading">Make it WOW has exceeded all our expectations</p>
                        <p className="reviews__text">Their expertise in UI/UX design and unwavering dedication to Dataspike KYC SaaS platform have truly exceeded our expectations. From creating captivating landing pages to designing seamless flows within our dashboard, Make it WOW’s attention to detail and creative prowess have elevated our user experience to new heights. Their contributions, including the design system, icons, and corporate materials, have strengthened our brand identity and left a lasting impact on our clients. We are grateful to have had the opportunity to collaborate with Make it WOW, and we highly recommend this agency of talented and professional UI/UX Designers.</p>
                    </div>
                </div>
                <div className="reviews__container">
                    <div className="reviews__info">
                        <img className="reviews__photo" src={reviewsFotoFourth} alt="CEO at Finangel"></img>
                        <div className="reviews__name-container">
                            <p className="reviews__name">Dmitriy Zaretskiy</p>
                            <p className="reviews__job-title">CEO at Finangel</p>
                        </div>
                    </div>
                    <div className="reviews__text-container">
                        <p className="reviews__heading">I am extremely satisfied with my collaboration with Make it WOW. The team demonstrated exceptional problem-solving skills and offered innovative ideas</p>
                        <p className="reviews__text">The team was able to successfully implemented high-quality solutions, tailored to different levels of complexity in our mobile app and web interfaces for our Financial Banking Product. They introduced new features that enhanced user engagement and improved the functionality of our Financial Banking App. Through our partnership, we have created a range of outstanding software products that have elevated the level of design within our company. I am grateful for the opportunity to work alongside this agency and look forward to embarking on more projects together in the future.</p>
                    </div>
                </div>
            </section>
            <section className="check">
                <button className="check__button" onClick={openProjects}>check our projects</button>
            </section>
        </div>
    );
}

export default Services;