function NextCase(props) {
    function onClick() {
        props.history.push(props.link);
    }

    return (
        <div className="next-case">
            <button className="next-case__button" onClick={onClick}>Next case</button>
        </div>
    );
}

export default NextCase;