import iconOk from "../../images/info-popup-icon-ok.png";
import iconError from "../../images/info-popup-icon-error.png";

function InfoPopup(props) {
    function closePopup() {
        props.openPopup(false);
    }

    return (
        <div className={`info-popup ${props.isOpen ? 'info-popup_type_is-open' : ''}`}>
            <div className="info-popup__content">
                <div className="info-popup__close-container">
                    <button className="info-popup__close" onClick={closePopup}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M6.29289 16.2929C5.90237 16.6834 5.90237 17.3166 6.29289 17.7071C6.68342 18.0976 7.31658 18.0976 7.70711 17.7071L12 13.4142L16.2929 17.7071C16.6834 18.0976 17.3166 18.0976 17.7071 17.7071C18.0976 17.3166 18.0976 16.6834 17.7071 16.2929L13.4142 12L17.7071 7.70711C18.0976 7.31658 18.0976 6.68342 17.7071 6.29289C17.3166 5.90237 16.6834 5.90237 16.2929 6.29289L12 10.5858L7.70711 6.29289C7.31658 5.90237 6.68342 5.90237 6.29289 6.29289C5.90237 6.68342 5.90237 7.31658 6.29289 7.70711L10.5858 12L6.29289 16.2929Z" fill="black"/>
                        </svg>
                    </button>
                </div>
                <div className="info-popup__info-container">
                    <img className="info-popup__icon" src={props.infoPopup ? iconOk : iconError} alt=""></img>
                    <p className="info-popup__title">{props.infoPopup ? 'Thank you for contact us!' : 'Sorry, something went wrong'}</p>
                    <p className="info-popup__text">{props.infoPopup ? 'Your request has been sent. We will contact you as soon as possible.' : "We couldn't complete your request. Please try again in a moment."}</p>
                    <button className="info-popup__button" onClick={closePopup}>{props.infoPopup ? 'Great' : 'Try again'}</button>
                </div>
            </div>
        </div>
    );
}

export default InfoPopup;