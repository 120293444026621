import Introduction from "../Introduction/Introduction";
import HeadingMedium from "../Heading/HeadingMedium";
import ImageMedium from "../Image/ImageMedium";
import CoupleImage from "../Image/CoupleImage";
import HeadingBig from "../Heading/HeadingBig";
import ImageMediumWithScroll from "../Image/ImageMediumWithScroll";
import ImageBig from "../Image/ImageBig";
import NextCase from "../NextCase";
import imageSmallFirst from "../../../images/definite-small-first.jpg";
import imageSmallSecond from "../../../images/definite-small-second.jpg";
import imageSmallThird from "../../../images/definite-small-third.jpg";
import imageSmallFourth from "../../../images/definite-small-fourth.jpg";
import imageMediumFirst from "../../../images/definite-medium-first.jpg";
import imageMediumSecond from "../../../images/definite-medium-second.jpg";
import imageMediumThird from "../../../images/definite-medium-third.jpg";
import imageBigFirst from "../../../images/definite-big-first.jpg";
import imageBigSecond from "../../../images/definite-big-second.jpg";

function Definite(props) {
    return (
        <div className="definite">
            <Introduction
                name={'Definite'}
                title={'Decentralized lending platform that uses smart contracts in place of intermediaries'}
                image={imageBigFirst}
                alt={'Definite'}
            />
            <HeadingMedium
                title={'The Client'}
                text={'Definite is a decentralized lending platform that uses smart contracts in place of intermediaries such as banks. With Definite, you basically get to liquidize your assets while maintaining a stake in crypto.'}
            />
            <ImageMedium
                padding={false}
                image={imageMediumFirst}
                alt={'Definite'}
            />
            <HeadingMedium
                title={'The Challenge'}
                text={'The goal set before us was to carry out full branding including the development of a logo, new corporate colors and fonts. An additional challenge was to make a landing page and an administrative panel.'}
            />
            <CoupleImage
                margin={false}
                firstImage={imageSmallFirst}
                firstAlt={'Definite'}
                secondImage={imageSmallSecond}
                secondAlt={'Definite'}
            />
            <HeadingBig
                title={'the result'}
            />
            <ImageMediumWithScroll
                margin={false}
                image={imageMediumSecond}
                alt={'Definite'}
            />
            <HeadingMedium
                title={'The Admin Panel'}
                text={'“Make it WOW” made a design for the administrative panel, which included 6 main tabs and 2 additional tabs.'}
            />
            <ImageMedium
                padding={true}
                image={imageMediumThird}
                alt={'Definite'}
            />
            <div className="image-with-text">
                <div className="image-with-text__container">
                    <img className="image-with-text__image" src={imageSmallThird} alt="Definite"></img>
                    <p className="image-with-text__text">Buttons and header components</p>
                </div>
                <div className="image-with-text__container image-with-text__couple_type_margin">
                    <img className="image-with-text__image" src={imageSmallFourth} alt="Definite"></img>
                    <p className="image-with-text__text">Inputs and left menu components</p>
                </div>
            </div>
            <ImageBig
                padding={true}
                image={imageBigSecond}
                alt={'Definite'}
            />
            <NextCase
                history={props.history}
                link={'/projects/doto'}
            />
        </div>
    );
}

export default Definite;