import IntroductionWithVideo from "../Introduction/IntroductionWithVideo";
import HeadingMedium from "../Heading/HeadingMedium";
import ImageMedium from "../Image/ImageMedium";
import ImageBig from "../Image/ImageBig";
import CoupleImage from "../Image/CoupleImage";
import HeadingBig from "../Heading/HeadingBig";
import ImageWithText from "../Image/ImagesWithText";
import NextCase from "../NextCase";
import imageSmallFirst from "../../../images/space-small-first.jpg";
import imageSmallSecond from "../../../images/space-small-second.jpg";
import imageSmallThird from "../../../images/space-small-third.jpg";
import imageSmallFourth from "../../../images/space-small-fourth.jpg";
import imageSmallFifth from "../../../images/space-small-fifth.jpg";
import imageSmallSixth from "../../../images/space-small-sixth.jpg";
import imageSmallSeventh from "../../../images/space-small-seventh.jpg";
import imageSmallEighth from "../../../images/space-small-eighth.jpg";
import imageSmallNinth from "../../../images/space-small-ninth.jpg";
import imageSmallTenth from "../../../images/space-small-tenth.jpg";
import imageMediumFirst from "../../../images/space-medium-first.jpg";
import imageMediumSecond from "../../../images/space-medium-second.jpg";
import imageMediumThird from "../../../images/space-medium-third.jpg";
import imageMediumFourth from "../../../images/space-medium-fourth.jpg";
import imageMediumFifth from "../../../images/space-medium-fifth.jpg";
import imageBigFirst from "../../../images/space-big-first.jpg";
import imageBigSecond from "../../../images/space-big-second.jpg";
import imageBigThird from "../../../images/space-big-third.jpg";
import video from "../../../video/space.mp4";

function Space(props) {
    return (
        <div className="space">
            <IntroductionWithVideo
                name={'space307'}
                title={'Trading Platform App: Desktop, Mobile Web, Android, iOS'}
                video={video}
                image={imageBigFirst}
            />
            <HeadingMedium
                title={'The Client'}
                text={'A cutting-edge trading platform that offer 3 distinct modes of trading, fiting different client needs and levels of proficiency.'}
            />
            <ImageMedium
                padding={false}
                image={imageMediumFirst}
                alt={'space307'}
            />
            <HeadingMedium
                title={'The Challenge'}
                text={'“Make it WOW” made a full redesign of the entire trading platform. We came up with a brand new color scheme, new price trend lines and charts, as well as a new set of icons.'}
            />
            <ImageMedium
                padding={false}
                image={imageMediumSecond}
                alt={'space307'}
            />
            <ImageBig
                padding={true}
                image={imageBigSecond}
                alt={'space307'}
            />
            <HeadingMedium
                title={'The Screen'}
                text={'More than 100+ different screens were developed both for the mobile version of the app and for the web version. The screens were expertly organized for the most enjoyable user experience.'}
            />
            <CoupleImage
                margin={false}
                firstImage={imageSmallFirst}
                firstAlt={'space307'}
                secondImage={imageSmallSecond}
                secondAlt={'space307'}
            />
            <CoupleImage
                margin={true}
                firstImage={imageSmallThird}
                firstAlt={'space307'}
                secondImage={imageSmallFourth}
                secondAlt={'space307'}
            />
            <HeadingBig
                title={'the result'}
            />
            <ImageMedium
                padding={false}
                image={imageMediumThird}
                alt={'space307'}
            />
            <HeadingMedium
                title={'Home Page'}
                text={'The product was presented in the most approachable way by showcasing only the most important features, latest updates and the best conditions for new customers.'}
            />
            <div className="image-with-text image__container_type_padding-bottom">
                <div className="image-with-text__container">
                    <img className="image-with-text__image" src={imageSmallFifth} alt="space307"></img>
                    <p className="image-with-text__text">Broker`s Home page</p>
                </div>
                <div className="image-with-text__container image-with-text__couple_type_margin">
                    <img className="image-with-text__image" src={imageSmallSixth} alt="space307"></img>
                    <p className="image-with-text__text">Fully responsive for all devices</p>
                </div>
            </div>
            <ImageMedium
                padding={true}
                image={imageMediumFourth}
                alt={'space307'}
            />
            <ImageWithText
                firstImage={imageSmallSeventh}
                firstAlt={'space307'}
                firstText={'Assets'}
                secondImage={imageSmallEighth}
                secondAlt={'space307'}
                secondText={'Trend Line drawing'}
                thirdImage={imageSmallNinth}
                thirdAlt={'space307'}
                thirdText={'Trading platform mobile app screens'}
                fourthImage={imageSmallTenth}
                fourthAlt={'space307'}
                fourthText={'Bottom Sheets'}
            />
            <HeadingMedium
                title={'Inner Pages'}
                text={`Low-level pages are often neglected in terms of design. That's never the case with Make it WOW.`}
            />
            <ImageMedium
                padding={false}
                image={imageMediumFifth}
                alt={'space307'}
            />
            <ImageBig
                padding={true}
                image={imageBigThird}
                alt={'space307'}
            />
            <NextCase
                history={props.history}
                link={'/projects/springbox'}
            />
        </div>
    );
}

export default Space;