import showreel from "../../video/showreel-mini.mp4";
import image from "../../images/main-screen.jpg";

function MainScreen(props) {
    function scroll() {
        document.querySelector('.main-screen__video').scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        });
    }

    return(
        <div className="main-screen">
            <div className="main-screen__text-container">
                <div className="main-screen__string main-screen__string_type_first">
                    <h2 className="main-screen__title">we are </h2>
                    <h2 className="main-screen__title main-screen__title_type_purple">Fintech</h2>
                </div>
                <h1 className="main-screen__title main-screen__string_type_second">design agency</h1>
            </div>
            <div className="main-screen__info">
                <p className="main-screen__about">Make it WOW is a full-service design agency in Dubai that helps Fintech businesses develop immersive and engaging user experiences that drive profit</p>
                <button
                    className="main-screen__button"
                    onClick={scroll}
                >
                    <div className="main-screen__button-arrow"></div>
                </button>
            </div>
            <div className="main-screen__video-container">
                <video className="main-screen__video" autoPlay muted loop poster={image} playsInline>
                    <source src={showreel} type="video/mp4" />
                </video>
                <button className="main-screen__play" onClick={props.onVideo}></button>
            </div>
        </div>
    );
}

export default MainScreen;