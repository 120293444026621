import IntroductionWithVideo from "../Introduction/IntroductionWithVideo";
import HeadingMedium from "../Heading/HeadingMedium";
import ImageMediumWithScroll from "../Image/ImageMediumWithScroll";
import ImageWithText from "../Image/ImagesWithText";
import ImageBig from "../Image/ImageBig";
import NextCase from "../NextCase";
import imageSmallFirst from "../../../images/doto-small-first.jpg";
import imageSmallSecond from "../../../images/doto-small-second.jpg";
import imageSmallThird from "../../../images/doto-small-third.jpg";
import imageSmallFourth from "../../../images/doto-small-fourth.jpg";
import imageMediumFirst from "../../../images/doto-medium-first.jpg";
import imageMediumSecond from "../../../images/doto-medium-second.jpg";
import imageBigFirst from "../../../images/doto-big-first.jpg";
import imageBigSecond from "../../../images/doto-big-second.jpg";
import videoFirst from "../../../video/doto-first.mp4";
import videoSecond from "../../../video/doto-second.mp4";

function Doto(props) {
    return (
        <div className="doto">
            <IntroductionWithVideo
                name={'Doto'}
                title={'forex Trading Platform website'}
                video={videoFirst}
                image={imageBigFirst}
            />
            <HeadingMedium
                title={'The Client'}
                text={'A trading software development company was preparing to launch their new proprietary trading platform into the Asian market.'}
            />
            <video className="main-screen__video_type_medium" autoPlay muted loop poster={imageMediumFirst} playsInline>
                <source src={videoSecond} type="video/mp4" />
            </video>
            <HeadingMedium
                title={'The Challenge'}
                text={'The market for Forex trading tools is very saturated. Our client wanted a website that would make their product stand out, showcasing the custom build of their platform without being too intimidating.'}
            />
            <div className="heading heading_type_none-padding-top">
                <h3 className="heading__title_type_big">The result</h3>
            </div>
            <ImageMediumWithScroll
                margin={false}
                image={imageMediumSecond}
                alt={'Doto'}
            />
            <HeadingMedium
                title={''}
                text={'The website designed by "Make It WOW" distinguishes itself from competition by its vivid pops of color and easy to understand infographics.'}
            />
            <ImageWithText
                firstImage={imageSmallFirst}
                firstAlt={'Doto'}
                firstText={'Different tiers of service'}
                secondImage={imageSmallSecond}
                secondAlt={'Doto'}
                secondText={'Reworked Asset Menu'}
                thirdImage={imageSmallThird}
                thirdAlt={'Doto'}
                thirdText={'An in-platform Marketplace'}
                fourthImage={imageSmallFourth}
                fourthAlt={'Doto'}
                fourthText={'Cross-platform Adaptivity'}
            />
            <ImageBig
                padding={true}
                image={imageBigSecond}
                alt={'Doto'}
            />
            <NextCase
                history={props.history}
                link={'/projects/assistalong'}
            />
        </div>
    );
}

export default Doto;