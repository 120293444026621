import Service from "../Main/Service";
import ContactUs from "../ContactUs/ContactUs";
import imageBigFirst from "../../images/pitch-deck-big-first.jpg";
import imageBigSecond from "../../images/pitch-deck-big-second.jpg";
import imageBigThird from "../../images/pitch-deck-big-third.png";
import imageBigFourth from "../../images/pitch-deck-big-fourth.jpg";
import imageFirst from "../../images/pitch-deck-first.jpg";
import imageSecond from "../../images/pitch-deck-second.jpg";
import imageThird from "../../images/pitch-deck-third.jpg";
import imageFormFirst from "../../images/pitch-deck-form-first.jpg";
import imageFormSecond from "../../images/pitch-deck-form-second.jpg";
import imageFormThird from "../../images/pitch-deck-form-third.jpg";
import imageFormFourth from "../../images/pitch-deck-form-fourth.jpg";
// import dataSpikePdf from "../../pdf/dataspike-sales.pdf";
// import assistAlongPdf from "../../pdf/assist-along-sales.pdf";
// import inceptionPdf from "../../pdf/inception-sales.pdf";
// import wowPdf from "../../pdf/make-it-wow-agency.pdf";
// import stakingPdf from "../../pdf/staking-circle-sales.pdf";

function PitchDeck(props) {
    function openContactUs() {
        props.history.push('/contact-us');
    }

    return (
        <div className="pitch-deck">
            <section className="pitch-deck__first-screen">
                <div className="pitch-deck__first-screen-content">
                    <div className="pitch-deck__first-screen-text-container">
                        <div className="pitch-deck__first-screen-heading">
                            <h2 className="main-screen__title main-screen__title_type_green main-screen__string_type_first pitch-deck__main-screen-title">We help to pitch</h2>
                            <h2 className="main-screen__title main-screen__title_type_white main-screen__string_type_second pitch-deck__main-screen-title">your business</h2>
                            <h2 className="main-screen__title main-screen__title_type_white main-screen__string_type_second pitch-deck__main-screen-title">idea to investors</h2>
                        </div>
                        <p className="pitch-deck__first-screen-text">If you need a presentation or an ideal pitch deck that can bring investment, we would be delighted to assist you. We combine fintech and design expertise to deliver exceptional, modern, and highly effective projects that drive your business towards its objectives.</p>
                    </div>
                    <div className="pitch-deck__first-screen-data-container">
                        <div className="pitch-deck__data pitch-deck__data_type_green">
                            <p className="miw__text miw__text_type_dark-blue">$50M+</p>
                            <p className="miw__text_type_small miw__text_type_black">raised investments</p>
                        </div>
                        <div className="pitch-deck__data pitch-deck__data_type_separator">
                            <p className="miw__text">300+</p>
                            <p className="miw__text_type_small">finished presentations</p>
                        </div>
                        <div className="pitch-deck__data pitch-deck__data_type_purple">
                            <p className="miw__text">3-5</p>
                            <p className="miw__text_type_small">working days</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="projects">
                <div className="projects__title-container">
                    <h2 className="industries__title projects__title">Projects of our</h2>
                    <div className="projects__title-string">
                        <h2 className="industries__title projects__title projects__title_type_purple">successful</h2>
                        <h2 className="industries__title projects__title">clients</h2>
                    </div>
                </div>
                <div className="industries__list">
                    <div className="industries__field">
                        <div className="industries__field-text-container">
                            <div className="projects__field-heading-container">
                                <p className="industries__field-title">DataSpike</p>
                                <a href="https://makeitwow.io/PDF/DataSpike-sales.pdf" target="_blank" rel="noreferrer" className="projects__field-button">
                                    <p className="projects__field-button-text">Download PDF</p>
                                    <div className="projects__arrow-download"></div>
                                </a>
                            </div>
                            <div className="projects__field-heading-container">
                                <p className="industries__field-text">We've reached the semifinals of the pitch deck competition on Gitex 2023 with a presentation by Make it WOW. Make it WOW supported us in creating the pitch deck about Smart & Safe City for Gitex 2023 in Morocco, the largest global tech show. The perfectly organised content in the presentation really boosted our performance.</p>
                                <p className="projects__field-text">Alex Petuchov. Head of Product at DataSpike.</p>
                            </div>
                        </div>
                        <a href="https://makeitwow.io/PDF/DataSpike-sales.pdf" target="_blank" rel="noreferrer" className="projects__link">
                            <div className="industries__image-container">
                                <img className="industries__image" src={imageBigFirst} alt="Dataspike"></img>
                                <div className="industries__image-text-container">
                                    <p className="industries__image-name">Dataspike</p>
                                    <p className="industries__image-text">Anti-Money Laundering Compliance Scanner</p>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="industries__field">
                        <div className="industries__field-text-container">
                            <div className="projects__field-heading-container">
                                <p className="industries__field-title">Assist Along</p>
                                <a href="https://makeitwow.io/PDF/AssistAlong-sales.pdf" target="_blank" rel="noreferrer" className="projects__field-button">
                                    <p className="projects__field-button-text">Download PDF</p>
                                    <div className="projects__arrow-download"></div>
                                </a>
                            </div>
                            <div className="projects__field-heading-container">
                                <p className="industries__field-text">Make it WOW created the perfect pitch deck, leading to substantial investment in our project. The presentation included details about how our service works, go-to-market plan, customer revenue profiles etc. We had several iterations working on the project and the result was an excellent pitch deck in both content and design.</p>
                                <p className="projects__field-text">Danial Ehyaie. CEO & Founder at Assist Along.</p>
                            </div>
                        </div>
                        <a href="https://makeitwow.io/PDF/AssistAlong-sales.pdf" target="_blank" rel="noreferrer" className="projects__link">
                            <div className="industries__image-container">
                                <img className="industries__image" src={imageBigSecond} alt="Assist Along"></img>
                                <div className="industries__image-text-container">
                                    <p className="industries__image-name">Assist Along</p>
                                    <p className="industries__image-text">E-Commerce Subscription Service</p>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="industries__field">
                        <div className="industries__field-text-container">
                            <p className="industries__field-title">Other Presentations</p>
                            <p className="industries__field-text">We also design pitch decks for many other industries and clients, including DeFi, staking, fintech, and more, helping our customers attract investments and present their projects effectively. Discover more of our pitch deck projects.</p>
                        </div>
                        <div className="industries__images">
                            <a href="https://makeitwow.io/PDF/Inception-sales.pdf" target="_blank" rel="noreferrer" className="projects__link">
                                <div className="industries__image-container">
                                    <img className="industries__image" src={imageFirst} alt="Inception"></img>
                                    <div className="industries__image-text-container">
                                        <p className="industries__image-name">Inception</p>
                                        <p className="industries__image-text">Restaking Protocol. Seamlessly restake stETH, rETH or cbETH.</p>
                                    </div>
                                    <div className="projects__field-button-second">
                                        <p className="projects__field-button-text projects__field-button-text_type_purple">Download PDF</p>
                                        <div className="projects__arrow-download_type_purple"></div>
                                    </div>
                                </div>
                            </a>
                            <a href="https://makeitwow.io/PDF/Make-it-WOW-Agency.pdf" target="_blank" rel="noreferrer" className="projects__link">
                                <div className="industries__image-container">
                                    <img className="industries__image" src={imageSecond} alt="Make it WOW"></img>
                                    <div className="industries__image-text-container">
                                        <p className="industries__image-name">Make it WOW</p>
                                        <p className="industries__image-text">Fintech Design Agency in Dubai</p>
                                    </div>
                                    <div className="projects__field-button-second">
                                        <p className="projects__field-button-text projects__field-button-text_type_purple">Download PDF</p>
                                        <div className="projects__arrow-download_type_purple"></div>
                                    </div>
                                </div>
                            </a>
                            <a href="https://makeitwow.io/PDF/Staking-circle-sales.pdf" target="_blank" rel="noreferrer" className="projects__link">
                                <div className="industries__image-container">
                                    <img className="industries__image" src={imageThird} alt="Staking Circle"></img>
                                    <div className="industries__image-text-container">
                                        <p className="industries__image-name">Staking Circle</p>
                                        <p className="industries__image-text">The exclusive community for elite DeFi professionals</p>
                                    </div>
                                    <div className="projects__field-button-second">
                                        <p className="projects__field-button-text projects__field-button-text_type_purple">Download PDF</p>
                                        <div className="projects__arrow-download_type_purple"></div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pitch-deck__advantages">
                <div className="pitch-deck__advantages-content">
                    <h2 className="pitch-deck__advantages-title">we are experts in fintech</h2>
                    <img className="pitch-deck__advantages-image" src={imageBigFourth} alt="We are experts in fintech"></img>
                    <div className="pitch-deck__advantages-info-container">
                        <div className="pitch-deck__advantages-info">
                            <div className="pitch-deck__advantages-icon"></div>
                            <p className="pitch-deck__advantages-text">We're experts in Fintech, ready to quickly engage in your project</p>
                        </div>
                        <div className="pitch-deck__advantages-info">
                            <div className="pitch-deck__advantages-icon"></div>
                            <p className="pitch-deck__advantages-text">Highly-skilled product team which handle all project stages</p>
                        </div>
                        <div className="pitch-deck__advantages-info">
                            <div className="pitch-deck__advantages-icon"></div>
                            <p className="pitch-deck__advantages-text">We provide a personal approach to meet your unique needs</p>
                        </div>
                        <div className="pitch-deck__advantages-info">
                            <div className="pitch-deck__advantages-icon"></div>
                            <p className="pitch-deck__advantages-text">You will get guaranteed and high-quality result in a short time</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="form">
                <h2 className="industries__title form__title">how we work</h2>
                <div className="form__cards">
                    <div className="form__card">
                        <img className="form__card-image" src={imageFormFirst} alt="Fast & Effective"></img>
                        <div className="form__text-container">
                            <p className="industries__field-title">Fast & Effective</p>
                            <p className="form__text">We can assist you quickly, which is crucial when there's limited time before your project launch. Usually, it takes 3-5 working days from an idea to result</p>
                        </div>
                    </div>
                    <div className="form__card">
                        <img className="form__card-image" src={imageFormSecond} alt="Transparent steps"></img>
                        <div className="form__text-container">
                            <p className="industries__field-title">Transparent steps</p>
                            <p className="form__text">We collaborate with you at each stage of the project to achieve the best possible result. Our clear process enables us to provide assistance and support at every point.</p>
                        </div>
                    </div>
                    <div className="form__card">
                        <img className="form__card-image" src={imageFormThird} alt="Always in touch"></img>
                        <div className="form__text-container">
                            <p className="industries__field-title">Always in touch</p>
                            <p className="form__text">We start with a short call to discuss your project tasks. During the project we'll stay in touch to have effective communication</p>
                        </div>
                    </div>
                    <div className="form__card">
                        <img className="form__card-image" src={imageFormFourth} alt="Make a project transparently"></img>
                        <div className="form__text-container">
                            <p className="industries__field-title">Access to working files</p>
                            <p className="form__text">We provide you with anytime access to working files, keeping you informed about progress and enabling smooth collaboration.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="submit-request">
                <div className="submit-request__content">
                    <div className="submit-request__info-container">
                        <div className="submit-request__text-container">
                            <h2 className="submit-request__title">Want to see more projects?</h2>
                            <p className="projects__field-text submit-request__text">Submit a request, and we'll prepare a presentation with relevant cases for your business.</p>
                        </div>
                        <img className="form__card-image" src={imageBigThird} alt="Projects"></img>
                    </div>
                    <button className="next-case__button submit-request__button" onClick={openContactUs}>contact us</button>
                </div>
            </section>
            <section className="faq-pitch-deck">
                <div className="faq-pitch-deck__content">
                    <h2 className="list-services__title">F.A.Q.</h2>
                    <div className="list-services__list">
                        <Service
                            serviceId={'pitch-deck-first-id'}
                            id={'pitch-deck-first'}
                            htmlFor={'pitch-deck-first'}
                            headingClass={'faq-pitch-deck__heading'}
                            textClass={'faq-pitch-deck__text'}
                            heading={'What is a Pitch Deck and why is it needed?'}
                            text={`A pitch deck is a concise and visually engaging presentation that provides an overview of a business idea, startup, or project to potential investors, partners, or stakeholders. It typically consists of a series of slides that include key information about the venture, such as its value proposition, market opportunity, team, financial projections, and more.`}
                        />
                        <Service
                            serviceId={'pitch-deck-second-id'}
                            id={'pitch-deck-second'}
                            htmlFor={'pitch-deck-second'}
                            headingClass={'faq-pitch-deck__heading'}
                            textClass={'faq-pitch-deck__text'}
                            heading={'What information should be in the Pitch Deck?'}
                            text={`Usually a Pitch Deck presentation includes these sections: Introduction, Problem & Solution, Market Opportunity, Business Model, Traction, Competition, Target Audience, Team, Financials, Use of Finds, Exit Strategy.`}
                        />
                        <Service
                            serviceId={'pitch-deck-third-id'}
                            id={'pitch-deck-third'}
                            htmlFor={'pitch-deck-third'}
                            headingClass={'faq-pitch-deck__heading'}
                            textClass={'faq-pitch-deck__text'}
                            heading={'How many slides does a Pitch Deck usually include?'}
                            text={`Remember that brevity and clarity are essential in a pitch deck. Each slide should convey information concisely, with a focus on visual elements and key points. Keep the deck to a manageable length, typically 10-15 slides, to maintain the audience's interest and engagement during your presentation.`}
                        />
                        <Service
                            serviceId={'pitch-deck-fourth-id'}
                            id={'pitch-deck-fourth'}
                            htmlFor={'pitch-deck-fourth'}
                            headingClass={'faq-pitch-deck__heading'}
                            textClass={'faq-pitch-deck__text'}
                            heading={'What is the difference between Pitch Deck and presentation?'}
                            text={`A pitch deck and a presentation are similar in that they both involve sharing information with an audience, but they have some key differences in terms of purpose, content, and format. Pitch deck is a specialized type of presentation tailored for fundraising and conveying the value of a business or project to potential investors. Presentations, on the other hand, are versatile tools used for a wide range of communication purposes beyond fundraising, and they can vary in content, format, and audience.`}
                        />
                    </div>
                </div>
            </section>
            <section className="pitch-deck__contact-us">
                <ContactUs
                    setInfoPopup={props.setInfoPopup}
                    openPopup={props.openPopup}
                />
            </section>
        </div>
    );
}

export default PitchDeck;