import IntroductionWithVideo from "../Introduction/IntroductionWithVideo";
import HeadingMedium from "../Heading/HeadingMedium";
import ImageMedium from "../Image/ImageMedium";
import CoupleImage from "../Image/CoupleImage";
import ImageBig from "../Image/ImageBig";
import HeadingBig from "../Heading/HeadingBig";
import ImageMediumWithScroll from "../Image/ImageMediumWithScroll";
import NextCase from "../NextCase";

import imageIntroduction from "../../../images/gami-introduction.jpg";
import imageSmallFirst from "../../../images/gami-small-first.jpg";
import imageSmallSecond from "../../../images/gami-small-second.jpg";
import imageSmallThird from "../../../images/gami-small-third.jpg";
import imageSmallFourth from "../../../images/gami-small-fourth.jpg";
import imageSmallFifth from "../../../images/gami-small-fifth.jpg";
import imageSmallSixth from "../../../images/gami-small-sixth.jpg";
import imageSmallSeventh from "../../../images/gami-small-seventh.jpg";
import imageSmallEighth from "../../../images/gami-small-eighth.jpg";

import imageMediumFirst from "../../../images/gami-medium-first.jpg";
import imageMediumSecond from "../../../images/gami-medium-second.jpg";
import imageMediumThird from "../../../images/gami-medium-third.jpg";
import imageMediumFourth from "../../../images/gami-medium-fourth.jpg";

import imageBigFirst from "../../../images/gami-big-first.jpg";
import imageBigSecond from "../../../images/gami-big-second.jpg";


function GamiTrading(props) {
    return (
        <div className="gami">
            <IntroductionWithVideo
                name={'Gami Trading'}
                title={'Trading Platform and website'}
                // video={}
                image={imageIntroduction}
            />
            <HeadingMedium
                title={'About'}
                text={'Gami Trading is an advanced trading platform that offers powerful risk management tools to help navigate the dynamic world of trading.'}
            />
            <ImageMedium
                padding={false}
                image={imageMediumFirst}
                alt={'Gami Trading'}
            />
            <HeadingMedium
                title={'The Challenge'}
                text={`Our agency was entrusted with the redesign of the trading platform and the development of the Gami Trading website. We've introduced a fresh visual identity for the platform, streamlining the trading process for both beginners and experienced users to enhance overall user experience.`}
            />
            <CoupleImage
                margin={false}
                firstImage={imageSmallFirst}
                firstAlt={'Gami Trading'}
                secondImage={imageSmallSecond}
                secondAlt={'Gami Trading'}
            />
            <CoupleImage
                margin={true}
                firstImage={imageSmallThird}
                firstAlt={'Gami Trading'}
                secondImage={imageSmallFourth}
                secondAlt={'Gami Trading'}
            />
            <HeadingBig
                title={'charts'}
            />
            <HeadingMedium
                title={'Mobile'}
                text={'Enhancing our trading platform for diverse devices, with a particular focus on mobile, was a top priority. Recognizing the increasing reliance on mobile devices for financial transactions, we ensured its seamless accessibility.'}
            />
            <ImageMedium
                padding={false}
                image={imageMediumSecond}
                alt={'Gami Trading'}
            />
            <ImageBig
                padding={true}
                image={imageBigFirst}
                alt={'Gami Trading'}
            />
            <HeadingMedium
                title={'Functionality and aesthetic finesse'}
                text={'In the ever-evolving landscape of financial markets, our dedicated team has meticulously crafted an array of exquisite screens to adorn and empower cutting-edge trading platform. These screens, each a testament to our unwavering commitment to excellence, stand as beacons of functionality and aesthetic finesse.'}
            />
            <CoupleImage
                margin={false}
                firstImage={imageSmallFifth}
                firstAlt={'Gami Trading'}
                secondImage={imageSmallSixth}
                secondAlt={'Gami Trading'}
            />
            <CoupleImage
                margin={true}
                firstImage={imageSmallSeventh}
                firstAlt={'Gami Trading'}
                secondImage={imageSmallEighth}
                secondAlt={'Gami Trading'}
            />
            <HeadingMedium
                title={'Multi Charts'}
                text ={'Recognizing the diverse needs of traders, the multi charts feature provides a comprehensive visual representation of market data, so that users can analyze several financial instruments simultaneously and make better trading decisions.'}
            />
            <ImageMedium
                padding={false}
                image={imageMediumThird}
                alt={'Gami Trading'}
            />
            <HeadingBig
                title={'website'}
            />
            <HeadingMedium
                title={'Home Page'}
                text={`As you step into the virtual realm of home page, you are not merely entering a digital space but embarking on an artistic journey. Here, the design is not just about visuals; it's a language that speaks to the traders, inviting them to partake in a sensory experience that goes beyond the transactional.`}
            />
            <ImageMediumWithScroll
                margin={false}
                image={imageMediumFourth}
                alt={'Gami Trading'}
            />
            <ImageBig
                padding={true}
                image={imageBigSecond}
                alt={'Gami Trading'}
            />
            <NextCase
                history={props.history}
                link={'/projects/pleh'}
            />
        </div>
    );
}

export default GamiTrading;