import Introduction from "../Introduction/Introduction";
import HeadingMedium from "../Heading/HeadingMedium";
import ImageMedium from "../Image/ImageMedium";
import ImageBig from "../Image/ImageBig";
import NextCase from "../NextCase";
import gifFirst from "../../../images/finangel-gif-first.gif";
import gifSecond from "../../../images/finangel-gif-second.gif";
import imageSmallFirst from "../../../images/finangel-small-first.jpg";
import imageSmallSecond from "../../../images/finangel-small-second.jpg";
import imageSmallThird from "../../../images/finangel-small-third.jpg";
import imageSmallFourth from "../../../images/finangel-small-fourth.jpg";
import imageSmallFifth from "../../../images/finangel-small-fifth.jpg";
import imageMediumFirst from "../../../images/finangel-medium-first.jpg";
import imageMediumSecond from "../../../images/finangel-medium-second.jpg";
import imageMediumThird from "../../../images/finangel-medium-third.jpg";
import imageBigFirst from "../../../images/finangel-big-first.jpg";

function Finangel(props) {
    return (
        <div className="finangel">
            <Introduction
                name={'Finangel'}
                title={'Digital bank App. Partners Page'}
                image={imageBigFirst}
                alt={'Finangel'}
            />
            <HeadingMedium
                title={'The Client'}
                text={'This Fintech project from the UK offers instant money transfers, as well as breaking down purchases into installments.'}
            />
            <ImageMedium
                padding={false}
                image={gifFirst}
                alt={'Finangel'}
            />
            <ImageMedium
                padding={false}
                image={imageMediumFirst}
                alt={'Finangel'}
            />
            <HeadingMedium
                title={'The Challenge'}
                text={'The task was to develop a UX / UI for their mobile application, a website, an admin panel and a partners page.'}
            />
            <div className="image-with-text">
                <div className="image-with-text__couple">
                    <div className="image-with-text__container">
                        <img className="image-with-text__image" src={imageSmallFirst} alt="Finangel"></img>
                    </div>
                </div>
                <div className="image-with-text__couple image-with-text__couple_type_position">
                    <div className="image-with-text__container">
                        <img className="image-with-text__image" src={imageSmallSecond} alt="Finangel"></img>
                    </div>
                    <div className="image-with-text__container">
                        <img className="image-with-text__image" src={imageSmallThird} alt="Finangel"></img>
                    </div>
                </div>
            </div>
            <HeadingMedium
                title={'Card Design'}
                text={'We were also tasked with developing a company logo as well as a design of a bank card the client issued. The final design was clean, modern and inspired by angel wings, while at the same time incorporating the letter "F" for Finangel.'}
            />
            <ImageMedium
                padding={false}
                image={imageMediumSecond}
                alt={'Finangel'}
            />
            <HeadingMedium
                title={'Payment Page'}
                text={'“Make it WOW” developed a sleek user interface for Finangel clients to manage their online purchases and to split bills with friends.'}
            />
            <ImageMedium
                padding={true}
                image={imageMediumThird}
                alt={'Finangel'}
            />
            <div className="image-with-text">
                <div className="image-with-text__couple">
                    <div className="image-with-text__container">
                        <img className="image-with-text__image" src={imageSmallFourth} alt="Finangel"></img>
                        <p className="image-with-text__text">Partners page fully responsive for all typpe of devices</p>
                    </div>
                </div>
                <div className="image-with-text__couple image-with-text__couple_type_margin">
                    <div className="image-with-text__container">
                        <img className="image-with-text__image" src={imageSmallFifth} alt="Finangel"></img>
                        <p className="image-with-text__text">Calm and friendly colors, easy typefaces</p>
                    </div>
                </div>
            </div>
            <HeadingMedium
                title={'The Result'}
                text={'An overarching goal for the designs for the entire project was to inspire a user with a sense of  calm, security and control they can gain while managing their finances with Finangel.'}
            />
            <ImageBig
                padding={false}
                image={gifSecond}
                alt={'Finangel'}
            />
            <NextCase
                history={props.history}
                link={'/projects/starfall'}
            />
        </div>
    );
}

export default Finangel;