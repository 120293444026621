import Introduction from "../Introduction/Introduction";
import HeadingMedium from "../Heading/HeadingMedium";
import ImageMedium from "../Image/ImageMedium";
import CoupleImage from "../Image/CoupleImage";
import HeadingBig from "../Heading/HeadingBig";
import ImageBig from "../Image/ImageBig";
import ImageMediumWithScroll from "../Image/ImageMediumWithScroll";
import NextCase from "../NextCase";
import imageSmallFirst from "../../../images/dataspike-small-first.jpg";
import imageSmallSecond from "../../../images/dataspike-small-second.jpg";
import imageSmallThird from "../../../images/dataspike-small-third.jpg";
import imageSmallFourth from "../../../images/dataspike-small-fourth.jpg";
import imageSmallFifth from "../../../images/dataspike-small-fifth.jpg";
import imageSmallSixth from "../../../images/dataspike-small-sixth.jpg";
import imageSmallSeventh from "../../../images/dataspike-small-seventh.jpg";
import imageSmallEighth from "../../../images/dataspike-small-eighth.jpg";
import imageSmallNinth from "../../../images/dataspike-small-ninth.jpg";
import imageSmallTenth from "../../../images/dataspike-small-tenth.jpg";
import imageSmallEleventh from "../../../images/dataspike-small-eleventh.jpg";
import imageSmallTwelfth from "../../../images/dataspike-small-twelfth.jpg";
import imageSmallThirteenth from "../../../images/dataspike-small-thirteenth.jpg";
import imageSmallFourteenth from "../../../images/dataspike-small-fourteenth.jpg";
import imageSmallFifteenth from "../../../images/dataspike-small-fifteenth.jpg";
import imageSmallSixteenth from "../../../images/dataspike-small-sixteenth.jpg";
import imageMediumFirst from "../../../images/dataspike-medium-first.jpg";
import imageMediumSecond from "../../../images/dataspike-medium-second.jpg";
import imageMediumThird from "../../../images/dataspike-medium-third.jpg";
import imageMediumFourth from "../../../images/dataspike-medium-fourth.jpg";
import imageMediumFifth from "../../../images/dataspike-medium-fifth.jpg";
import imageMediumSixth from "../../../images/dataspike-medium-sixth.jpg";
import imageBigFirst from "../../../images/dataspike-big-first.jpg";
import imageBigSecond from "../../../images/dataspike-big-second.jpg";
import imageBigThird from "../../../images/dataspike-big-third.jpg";
import imageBigFourth from "../../../images/dataspike-big-fourth.jpg";

function DataSpike(props) {
    return (
        <div className="dataspike">
            <Introduction
                name={'Dataspike'}
                title={'An all-in-one platform offering Anti-Money Laundering and compliance services'}
                image={imageBigFirst}
                alt={'Dataspike'}
            />
            <HeadingMedium
                title={'The Client'}
                text={'An AI-powered online platform as well as an API solution for screening new clients for being on sanctions / PEP lists.'}
            />
            <ImageMedium
                padding={false}
                image={imageMediumFirst}
                alt={'Dataspike'}
            />
            <HeadingMedium
                title={'The Challenge'}
                text={'The task was  to come up with a design for the main page, internal pages, as well as an admin panel with a dashboard. Billing and registration pages had to be redesigned. A new AML/KYC algorithm was developed in the process as well.'}
            />
            <CoupleImage
                margin={false}
                firstImage={imageSmallFirst}
                firstAlt={'Dataspike'}
                secondImage={imageSmallSecond}
                secondAlt={'Dataspike'}
            />
            <CoupleImage
                margin={true}
                firstImage={imageSmallThird}
                firstAlt={'Dataspike'}
                secondImage={imageSmallFourth}
                secondAlt={'Dataspike'}
            />
            <HeadingBig
                title={'platform'}
            />
            <ImageMedium
                padding={false}
                image={imageMediumSecond}
                alt={'Dataspike'}
            />
            <HeadingMedium
                title={'AML Solution'}
                text={'Fast and convenient search, where it is fashionably easy to apply filters, find the necessary person or organisation and then download the PDF report.'}
            />
            <CoupleImage
                margin={false}
                firstImage={imageSmallFifth}
                firstAlt={'Dataspike'}
                secondImage={imageSmallSixth}
                secondAlt={'Dataspike'}
            />
            <CoupleImage
                margin={true}
                firstImage={imageSmallSeventh}
                firstAlt={'Dataspike'}
                secondImage={imageSmallEighth}
                secondAlt={'Dataspike'}
            />
            <ImageBig
                padding={true}
                image={imageBigSecond}
                alt={'Dataspike'}
            />
            <HeadingMedium
                title={'KYC Solution'}
                text={'A stunning user-friendly interface has been created, where the user can go through a full document check in a minute.'}
            />
            <CoupleImage
                margin={false}
                firstImage={imageSmallNinth}
                firstAlt={'Dataspike'}
                secondImage={imageSmallTenth}
                secondAlt={'Dataspike'}
            />
            <CoupleImage
                margin={true}
                firstImage={imageSmallEleventh}
                firstAlt={'Dataspike'}
                secondImage={imageSmallTwelfth}
                secondAlt={'Dataspike'}
            />
            <HeadingMedium
                title={'Mobile SDK'}
                text={'Also, additionally, we have created a convenient mobile SDK with identity verification.'}
            />
            <ImageMedium
                padding={false}
                image={imageMediumThird}
                alt={'Dataspike'}
            />
            <ImageBig
                padding={true}
                image={imageBigThird}
                alt={'Dataspike'}
            />
            <HeadingMedium
                title={'Applicants, Billing & Settings'}
                text={'Additional pages - creating applicants, organisation and profile settings, bullying and much more.'}
            />
            <CoupleImage
                margin={false}
                firstImage={imageSmallThirteenth}
                firstAlt={'Dataspike'}
                secondImage={imageSmallFourteenth}
                secondAlt={'Dataspike'}
            />
            <CoupleImage
                margin={true}
                firstImage={imageSmallFifteenth}
                firstAlt={'Dataspike'}
                secondImage={imageSmallSixteenth}
                secondAlt={'Dataspike'}
            />
            <HeadingBig
                title={'website'}
            />
            <ImageMediumWithScroll
                margin={true}
                image={imageMediumFourth}
                alt={'Dataspike'}
            />
            <ImageMedium
                padding={false}
                image={imageMediumFifth}
                alt={'Dataspike'}
            />
            <HeadingMedium
                title={''}
                text={'The website and the admin panel are perfectly resizable and look great when being accessed from any device.'}
            />
            <ImageMedium
                padding={false}
                image={imageMediumSixth}
                alt={'Dataspike'}
            />
            <ImageBig
                padding={true}
                image={imageBigFourth}
                alt={'Dataspike'}
            />
            <NextCase
                history={props.history}
                link={'/projects/space307'}
            />
        </div>
    );
}

export default DataSpike;