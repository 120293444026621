import emiratesNBDBankConcept from "../../images/emirates-nbd-bank-concept.png";
import dataspikePitchDeck from "../../images/data-spike-pitch-deck.png";
import assistAlongPitchDeck from "../../images/assist-along-pitch-deck.png";
import genesisRestaking from "../../images/genesis-restaking.png";
import aiEducationPlatformBranding from "../../images/ai-education-platform-branding.webp";
import aiEducationPlatformFirst from "../../images/ai-education-platform-first.webp";
import aiEducationPlatformSecond from "../../images/ai-education-platform-second.webp";
import taskTrackerDashboard from "../../images/task-tracker-dashboard.webp";
import davosApp from "../../images/davos-app.webp";
import dataspikeNewWebsite from "../../images/dataspike-new-website.webp";
import acmeClickCryptoCheckout from "../../images/acme-1-click-crypto-checkout.webp";
import cerosYieldConverter from "../../images/ceros-yield-converter.webp";
import springboxAiCaseStudy from "../../images/springbox-ai-case-study.webp";

function Dribbble() {
    function openDribbble() {
        window.open('https://dribbble.com/makeitwow');
    }

    return (
        <div className="dribbble">
            <div className="dribbble__content">
                <div className="dribbble__info">
                    <h2 className="dribbble__title">Check out our Dribbble for more cool designs</h2>
                    <button className="dribbble__link" onClick={openDribbble}>
                        <p className="dribbble__link_type_text">Go to Dribbble</p>
                        <svg className="dribbble__link_type_arrow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M9.29289 3.29289C9.68342 2.90237 10.3166 2.90237 10.7071 3.29289L14.7071 7.29289C15.0976 7.68342 15.0976 8.31658 14.7071 8.70711L10.7071 12.7071C10.3166 13.0976 9.68342 13.0976 9.29289 12.7071C8.90237 12.3166 8.90237 11.6834 9.29289 11.2929L11.5858 9H2C1.44772 9 1 8.55228 1 8C1 7.44772 1.44772 7 2 7H11.5858L9.29289 4.70711C8.90237 4.31658 8.90237 3.68342 9.29289 3.29289Z"/>
                        </svg>
                    </button>
                </div>
                <div className="dribbble__images-container">
                    <img className="dribbble__image"src={emiratesNBDBankConcept} alt="Emirates NBD Bank. Concept"></img>
                    <img className="dribbble__image"src={dataspikePitchDeck} alt="DataSpike. Pitch Deck."></img>
                    <img className="dribbble__image"src={assistAlongPitchDeck} alt="Assist Along. Pitch Deck."></img>
                    <img className="dribbble__image"src={genesisRestaking} alt="Genesis – Restaking ETH. Crypto."></img>
                    <img className="dribbble__image" src={aiEducationPlatformBranding} alt="AI Education Platform Branding"></img>
                    <img className="dribbble__image" src={aiEducationPlatformFirst} alt="AI Education Platform"></img>
                    <img className="dribbble__image" src={aiEducationPlatformSecond} alt="AI Education Platform"></img>
                    <img className="dribbble__image" src={taskTrackerDashboard} alt="Task Tracker. Dashboard"></img>
                    <img className="dribbble__image" src={davosApp} alt="Davos App"></img>
                    <img className="dribbble__image" src={dataspikeNewWebsite} alt="DataSpike. New Website"></img>
                    <img className="dribbble__image" src={acmeClickCryptoCheckout} alt="Acme. 1-click Crypto checkout"></img>
                    <img className="dribbble__image" src={cerosYieldConverter} alt="Ceros. Yield Converter"></img>
                    <img className="dribbble__image" src={springboxAiCaseStudy} alt="Springbox Ai. Case Study"></img>
                </div>
            </div>
        </div>
    );
}

export default Dribbble;