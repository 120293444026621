import IntroductionWithVideo from "../Introduction/IntroductionWithVideo";
import HeadingMedium from "../Heading/HeadingMedium";
import ImageMedium from "../Image/ImageMedium";
import ImageMediumWithScroll from "../Image/ImageMediumWithScroll";
import NextCase from "../NextCase";
import imageIntroduction from "../../../images/sikka-introduction.jpg";
import imageMediumFirst from "../../../images/sikka-medium-first.jpg";
import imageMediumSecond from "../../../images/sikka-medium-second.jpg";
import imageMediumThird from "../../../images/sikka-medium-third.jpg";
import video from "../../../video/sikka.mp4";

function Sikka(props) {
    return (
        <div className="sikka">
            <IntroductionWithVideo
                name={'Sikka'}
                title={'Lending Protocol for US dollars stablecoin'}
                video={video}
                image={imageIntroduction}
            />
            <HeadingMedium
                title={'The Client'}
                text={'Sikka run high availability secure cryptographic signing nodes to validate some of the largest Proof of Stake blockchains. They use intimate technical knowledge of PoS systems into high quality operational validators.'}
            />
            <ImageMedium
                padding={false}
                image={imageMediumFirst}
                alt={'Sikka'}
            />
            <HeadingMedium
                title={'The Challenge'}
                text={'The task was to create a new visual style for the brand: a new color scheme and a completely redesigned interactive landing page.'}
            />
            <div className="heading heading_type_none-padding-top">
                <h3 className="heading__title_type_big">The result</h3>
            </div>
            <ImageMediumWithScroll
                margin={false}
                image={imageMediumSecond}
                alt={'Sikka'}
            />
            <HeadingMedium
                title={'The Result'}
                text={'Finaly, we got a beautiful landing page with vibrant colors. The picture was thought out and the interactive part of the pages with the bank was implemented.'}
            />
            <ImageMedium
                padding={false}
                image={imageMediumThird}
                alt={'Sikka'}
            />
            <NextCase
                history={props.history}
                link={'/projects/finangel'}
            />
        </div>
    );
}

export default Sikka;