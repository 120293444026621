function Header(props) {
    return (
        <div className="header">
            <div className={`header__container ${props.background}`}>
                <div className="header__content">
                    <svg className={`header__logo ${props.logo}`} onClick={props.onClick} xmlns="http://www.w3.org/2000/svg" width="32" height="22" viewBox="0 0 32 22" fill="black">
                        <path d="M28.2863 7.71118C28.3523 7.71118 28.4178 7.70939 28.483 7.70585C28.6097 8.92945 28.3021 9.58682 27.9593 10.0687C27.3933 10.8643 28.0472 11.4555 28.8447 10.915C30.75 9.62379 32.0785 7.03861 31.9963 3.55901L31.9851 3.55274C31.8365 1.56491 30.2372 0 28.2863 0C26.2373 0 24.5762 1.72621 24.5762 3.85559C24.5762 5.98497 26.2373 7.71118 28.2863 7.71118Z"/>
                        <path d="M1.81163 1.2697C0.00713229 2.4415 -0.541626 4.91164 0.585952 6.78692L8.60136 20.1173C9.72894 21.9926 12.1059 22.5629 13.9104 21.3911C14.2847 21.148 14.605 20.8491 14.8675 20.5111C15.6521 19.6634 17.1007 17.8505 17.6658 15.5116C17.7336 15.2312 17.8012 14.9412 17.8683 14.6452L21.1586 20.1173C22.2862 21.9926 24.6631 22.5629 26.4676 21.3911C28.2721 20.2193 28.8209 17.7491 27.6933 15.8739L19.6779 2.54345C18.5503 0.668177 16.1734 0.0979002 14.3689 1.2697C12.5644 2.4415 12.0156 4.91164 13.1432 6.78692L15.4549 10.6315C15.545 12.386 15.3881 14.0844 14.6796 15.1148L7.12063 2.54345C5.99305 0.668177 3.61613 0.0979002 1.81163 1.2697Z"/>
                    </svg>
                    <button className="menu" onClick={props.openMenu}>
                        <h3 className={props.menu}>menu</h3>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className={props.burger}>
                            <path d="M3 6.99707C2.44772 6.99707 2 7.44479 2 7.99707C2 8.54936 2.44772 8.99707 3 8.99707H20.9977C21.55 8.99707 21.9977 8.54936 21.9977 7.99707C21.9977 7.44479 21.55 6.99707 20.9977 6.99707H3ZM3 14.9971C2.44772 14.9971 2 15.4448 2 15.9971C2 16.5494 2.44772 16.9971 3 16.9971L20.9977 16.9971C21.55 16.9971 21.9977 16.5494 21.9977 15.9971C21.9977 15.4448 21.55 14.9971 20.9977 14.9971L3 14.9971Z"/>
                        </svg>
                    </button>
                </div>
            </div>
            <div className="header_type_border"></div>
        </div>
    );
}

export default Header;