import IntroductionWithVideo from "../Introduction/IntroductionWithVideo";
import HeadingMedium from "../Heading/HeadingMedium";
import ImageMedium from "../Image/ImageMedium";
import CoupleImage from "../Image/CoupleImage";
import ImageBig from "../Image/ImageBig";
import HeadingBig from "../Heading/HeadingBig";
import ImageWithText from "../Image/ImagesWithText";
import NextCase from "../NextCase";
import imageIntroduction from "../../../images/springbox-introduction.jpg";
import imageSmallFirst from "../../../images/challenge-springbox-small-first.jpg";
import imageSmallSecond from "../../../images/challenge-springbox-small-third.jpg";
import imageSmallThird from "../../../images/challenge-springbox-small-second.jpg";
import imageSmallFourth from "../../../images/challenge-springbox-small-fourth.jpg";
import imageSmallFifth from "../../../images/chart-springbox-small-first.jpg";
import imageSmallSixth from "../../../images/chart-springbox-small-third.jpg";
import imageSmallSeventh from "../../../images/chart-springbox-small-second.jpg";
import imageSmallEighth from "../../../images/chart-springbox-small-fourth.jpg";
import imageMediumFirst from "../../../images/springbox-client.jpg";
import imageMediumSecond from "../../../images/chart-springbox-medium-first.jpg";
import imageMediumThird from "../../../images/chart-springbox-medium-second.jpg";
import imageBigFirst from "../../../images/challenge-springbox-big.jpg";
import imageBigSecond from "../../../images/chart-springbox-big-first.jpg";
import imageBigThird from "../../../images/chart-springbox-big-second.jpg";
import imageBigFourth from "../../../images/chart-springbox-big-third.jpg";
import video from "../../../video/springbox.mp4";

function Springbox(props) {
    return (
        <div className="springbox">
            <IntroductionWithVideo
                name={'Springbox AI'}
                title={'Trading Platform : App Design, Web Design, UI Kit and Indicators Interface'}
                video={video}
                image={imageIntroduction}
            />
            <HeadingMedium
                title={'The Client'}
                text={'Springbox AI uses advanced algorithms able to identify high market patterns profitability, so that you can take full advantage of any trading opportunity.'}
            />
            <ImageMedium
                padding={false}
                image={imageMediumFirst}
                alt={'Springbox AI'}
            />
            <HeadingMedium
                title={'The Challenge'}
                text={'On this project we were faced with several challenges. First, developing a delivery system for trading signals. Then, developing a user interface free of overwhelming complexity. And lastly, a system of real-time alerts and push notifications leveraging the AI at the heart of the app.'}
            />
            <CoupleImage
                margin={false}
                firstImage={imageSmallFirst}
                firstAlt={'Springbox AI'}
                secondImage={imageSmallSecond}
                secondAlt={'Springbox AI'}
            />
            <CoupleImage
                margin={true}
                firstImage={imageSmallThird}
                firstAlt={'Springbox AI'}
                secondImage={imageSmallFourth}
                secondAlt={'Springbox AI'}
            />
            <ImageBig
                padding={true}
                image={imageBigFirst}
                alt={'Springbox AI'}
            />
            <HeadingBig
                title={'Chart'}
            />
            <ImageMedium
                padding={false}
                image={imageMediumSecond}
                alt={'Springbox AI'}
            />
            <ImageBig
                padding={true}
                image={imageBigSecond}
                alt={'Springbox AI'}
            />
            <HeadingMedium
                title={'Mobile'}
                text={'The interfaces developed by Make it WOW for this app inspire the user with confidence in their ability to master such complex subjects as trading and investing.'}
            />
            <ImageWithText
                firstImage={imageSmallFifth}
                firstAlt={'Springbox AI'}
                firstText={'Smart engine is constantly analyzing millions of data values'}
                secondImage={imageSmallSeventh}
                secondAlt={'Springbox AI'}
                secondText={'Perfect design system'}
                thirdImage={imageSmallSixth}
                thirdAlt={'Springbox AI'}
                thirdText={'To generate real-time accurate trading signals'}
                fourthImage={imageSmallEighth}
                fourthAlt={'Springbox AI'}
                fourthText={'Every screen is thought out to the smallest detail'}
            />
            <HeadingMedium
                title={'Analysis made simple'}
                text={'Our approach to visualizing technical analysis is paired-back but not compromising on being informative to the user. '}
            />
            <ImageMedium
                padding={false}
                image={imageMediumThird}
                alt={'Springbox AI'}
            />
            <ImageBig
                padding={true}
                image={imageBigThird}
                alt={'Springbox AI'}
            />
            <ImageBig
                padding={false}
                image={imageBigFourth}
                alt={'Springbox AI'}
            />
            <NextCase
                history={props.history}
                link={'/projects/acme'}
            />
        </div>
    );
}

export default Springbox;