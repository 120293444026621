function CoupleOfProjects (props) {
    function onClickFirst() {
        props.history.push(props.linkFirst);
    }

    function onClickSecond() {
        props.history.push(props.linkSecond);
    }

    return (
        <div className="couple-of-projects">
            <div className="couple-of-projects__first-project">
                <div className="couple-of-projects__description couple-of-projects__description_type_first">
                    <div className="couple-of-projects__description-text">
                        <p className="couple-of-projects__name">{props.nameFirst}</p>
                        <p className="couple-of-projects__heading">{props.headingFirst}</p>
                    </div>
                    <button className="couple-of-projects__button" onClick={onClickFirst}>View project</button>
                </div>
                <div className="couple-of-projects__image-container">
                    <img className="couple-of-projects__image" src={props.imageFirst} alt={props.nameFirst}></img>
                </div>
            </div>
            <div className="couple-of-projects__second-project">
                <div className="couple-of-projects__image-container">
                    <img className="couple-of-projects__image" src={props.imageSecond} alt={props.nameSecond}></img>
                </div>
                <div className="couple-of-projects__description couple-of-projects__description_type_second">
                    <div className="couple-of-projects__description-text">
                        <p className="couple-of-projects__name">{props.nameSecond}</p>
                        <p className="couple-of-projects__heading">{props.headingSecond}</p>
                    </div>
                    <button className="couple-of-projects__button couple-of-projects__button_type_margin-bottom" onClick={onClickSecond}>View project</button>
                </div>
            </div>
        </div>
    );
}

export default CoupleOfProjects;