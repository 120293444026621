import space from "../../images/space-small.png";
import finangel from "../../images/finangel-small.jpg";
import arrow from "../../images/arrow-small.jpg";
import springbox from "../../images/springbox-small.jpg";

function Miw() {
    return (
        <div className="miw">
            <div className="miw__content">
                <div className="miw__text-container">
                <p className="miw__text">Our experience <img className="miw__image miw__image_type_space" src={space} alt="space307"></img> in fintech <img className="miw__image miw__image_type_finangel" src={finangel} alt="Finangel"></img> design gives us a <img className="miw__image miw__image_type_arrow" src={arrow} alt="arrow"></img> vantage point other design agencies are not <img className="miw__image miw__image_type_springbox" src={springbox} alt="Springbox AI"></img> privy to</p>
                </div>
                <div className="miw__data-container">
                    <div className="miw__data">
                        <p className="miw__text">15+</p>
                        <p className="miw__text_type_small">years of experience</p>
                    </div>
                    <div className="miw__data">
                        <p className="miw__text">300</p>
                        <p className="miw__text_type_small">completed projects</p>
                    </div>
                    <div className="miw__data">
                        <p className="miw__text">25M</p>
                        <p className="miw__text_type_small">monthly active users</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Miw;