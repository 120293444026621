import Introduction from "../Introduction/Introduction";
import HeadingMedium from "../Heading/HeadingMedium";
import ImageMedium from "../Image/ImageMedium";
import CoupleImage from "../Image/CoupleImage";
import HeadingBig from "../Heading/HeadingBig";
import ImageMediumWithScroll from "../Image/ImageMediumWithScroll";
import ImageBig from "../Image/ImageBig";
import NextCase from "../NextCase";
import imageSmallFirst from "../../../images/assist-small-first.jpg";
import imageSmallSecond from "../../../images/assist-small-second.jpg";
import imageMediumFirst from "../../../images/assist-medium-first.jpg";
import imageMediumSecond from "../../../images/assist-medium-second.jpg";
import imageMediumThird from "../../../images/assist-medium-third.jpg";
import imageMediumFourth from "../../../images/assist-medium-fourth.jpg";
import imageMediumFifth from "../../../images/assist-medium-fifth.jpg";
import imageMediumSixth from "../../../images/assist-medium-sixth.jpg";
import imageMediumSeventh from "../../../images/assist-medium-seventh.jpg";
import imageBigFirst from "../../../images/assist-big-first.jpg";
import imageBigSecond from "../../../images/assist-big-second.jpg";

function AssistAlong(props) {
    return (
        <div className="assist-along">
            <Introduction
                name={'Assist Along'}
                title={'Website for a subscription service'}
                image={imageBigFirst}
                alt={'Assist Along'}
            />
            <HeadingMedium
                title={'The Client'}
                text={'Assist Along complement your subscription model to convert your non-subscribers into recurring customers without requiring any upfront commitment'}
            />
            <ImageMedium
                padding={false}
                image={imageMediumFirst}
                alt={'Assist Along'}
            />
            <HeadingMedium
                title={'The Challenge'}
                text={'Our agency executed a redesign of the entire website and updated corporate colors. We also made designs for promo email GIFs, as well as their page on Shopify.'}
            />
            <CoupleImage
                margin={false}
                firstImage={imageSmallFirst}
                firstAlt={'Assist Along'}
                secondImage={imageSmallSecond}
                secondAlt={'Assist Along'}
            />
            <div className="image__container image__container_type_medium image__container_type_padding">
                <img className="image" src={imageMediumSecond} alt="Assist Along"></img>
            </div>
            <HeadingBig
                title={'The result'}
            />
            <ImageMediumWithScroll
                margin={false}
                image={imageMediumThird}
                alt={'Assist Along'}
            />
            <HeadingMedium
                title={'Home Page'}
                text={'We came up with proprietary characters that the user can now associate with the brand.'}
            />
            <ImageMedium
                padding={true}
                image={imageMediumFourth}
                alt={'Assist Along'}
            />
            <ImageMedium
                padding={false}
                image={imageMediumFifth}
                alt={'Assist Along'}
            />
            <HeadingMedium
                title={'Home Page'}
                text={'The redesign helped the store look more modern and lighter. A beautiful new style from our agency gives the pages of the site a special charm and a memorable style.'}
            />
            <ImageMedium
                padding={true}
                image={imageMediumSixth}
                alt={'Assist Along'}
            />
            <ImageMedium
                padding={false}
                image={imageMediumSeventh}
                alt={'Assist Along'}
            />
            <ImageBig
                padding={true}
                image={imageBigSecond}
                alt={'Assist Along'}
            />
            <NextCase
                history={props.history}
                link={'/projects/sikka'}
            />
        </div>
    );
}

export default AssistAlong;