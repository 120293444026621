function ImageWithText(props) {
    return (
        <div className="image-with-text">
            <div className="image-with-text__couple">
                <div className="image-with-text__container">
                    <img className="image-with-text__image" src={props.firstImage} alt={props.firstAlt}></img>
                    <p className="image-with-text__text">{props.firstText}</p>
                </div>
                <div className="image-with-text__container">
                    <img className="image-with-text__image" src={props.secondImage} alt={props.secondAlt}></img>
                    <p className="image-with-text__text">{props.secondText}</p>
                </div>
            </div>
            <div className="image-with-text__couple image-with-text__couple_type_margin">
                <div className="image-with-text__container">
                    <img className="image-with-text__image" src={props.thirdImage} alt={props.thirdAlt}></img>
                    <p className="image-with-text__text">{props.thirdText}</p>
                </div>
                <div className="image-with-text__container">
                    <img className="image-with-text__image" src={props.fourthImage} alt={props.fourthAlt}></img>
                    <p className="image-with-text__text">{props.fourthText}</p>
                </div>
            </div>
        </div>
    );
}

export default ImageWithText;