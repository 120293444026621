function CoupleImage(props) {
    return (
        <div className={`couple-image ${props.margin ? 'couple-image_type_margin' : ''}`}>
            <div className="couple-image__container">
                <img className="couple-image__image" src={props.firstImage} alt={props.fistAlt}></img>
            </div>
            <div className="couple-image__container">
                <img className="couple-image__image couple-image__image_type_margin-top" src={props.secondImage} alt={props.secondAlt}></img>
            </div>
        </div>
    );
}

export default CoupleImage;