function VideoPopup(props) {
    return (
        <div className={`popup ${props.isOpen ? 'popup_is-opened' : ''}`}>
            <div className="popup__content">
                <iframe className="popup__video" src={`${props.isOpen ? 'https://player.vimeo.com/video/802407767?h=eca4230832&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479' : ''}`} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" title="showreel"></iframe>
                <button className="popup__close" onClick={props.onClose}></button>
            </div>
        </div>
    );
}

export default VideoPopup;