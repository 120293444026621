import IntroductionWithVideo from "../Introduction/IntroductionWithVideo";
import HeadingMedium from "../Heading/HeadingMedium";
import ImageMedium from "../Image/ImageMedium";
import CoupleImage from "../Image/CoupleImage";
import HeadingBig from "../Heading/HeadingBig";
import ImageBig from "../Image/ImageBig";
import ImageMediumWithScroll from "../Image/ImageMediumWithScroll";
import NextCase from "../NextCase";
import imageIntroduction from "../../../images/starfall-introduction.jpg";
import imageSmallFirst from "../../../images/starfall-small-first.jpg";
import imageSmallSecond from "../../../images/starfall-small-second.jpg";
import imageSmallThird from "../../../images/starfall-small-third.jpg";
import imageSmallFourth from "../../../images/starfall-small-fourth.jpg";
import imageSmallFifth from "../../../images/starfall-small-fifth.jpg";
import imageSmallSixth from "../../../images/starfall-small-sixth.jpg";
import imageSmallSeventh from "../../../images/starfall-small-seventh.jpg";
import imageSmallEighth from "../../../images/starfall-small-eighth.jpg";
import imageSmallNinth from "../../../images/starfall-small-ninth.jpg";
import imageSmallTenth from "../../../images/starfall-small-tenth.jpg";
import imageMediumFirst from "../../../images/starfall-medium-first.jpg";
import imageMediumSecond from "../../../images/starfall-medium-second.jpg";
import imageMediumThird from "../../../images/starfall-medium-third.jpg";
import imageBigFirst from "../../../images/starfall-big-first.jpg";
import imageBigSecond from "../../../images/starfall-big-second.jpg";
import imageBigThird from "../../../images/starfall-big-third.jpg";
import videoFirst from "../../../video/starfall-first.mp4";
import videoSecond from "../../../video/starfall-second.mp4";
import videoThird from "../../../video/starfall-third.mp4";

function Starfall(props) {
    return (
        <div className="starfall">
            <IntroductionWithVideo
                name={'Starfall'}
                title={'new-generation web3 moba game'}
                video={videoFirst}
                image={imageIntroduction}
            />
            <HeadingMedium
                title={'About'}
                text={'Starfall Arena is a next-generation Web3 Moba Game with a unique universe, amazing heroes and the opportunity to receive crypto for winnings, as well as participate in eSports competitions.'}
            />
            <ImageMedium
                padding={false}
                image={imageMediumFirst}
                alt={'Starfall'}
            />
            <HeadingMedium
                title={'The Challenge'}
                text={'We were faced with the task of developing a new magnificent landing page that would display the spirit of the game. Also we designed the web app to enhance user experience.'}
            />
            <CoupleImage
                margin={false}
                firstImage={imageSmallFirst}
                firstAlt={'Starfall'}
                secondImage={imageSmallSecond}
                secondAlt={'Starfall'}
            />
            <HeadingBig
                title={'web app'}
            />
            <ImageMedium
                padding={false}
                image={imageMediumSecond}
                alt={'Starfall'}
            />
            <HeadingMedium
                title={''}
                text={`We've designed an intuitive web app, allowing users effortlessly create an account, acquire heroes, cosmetics or boxes with crypto and view transaction history.`}
            />
            <CoupleImage
                margin={false}
                firstImage={imageSmallThird}
                firstAlt={'Starfall'}
                secondImage={imageSmallFourth}
                secondAlt={'Starfall'}
            />
            <CoupleImage
                margin={true}
                firstImage={imageSmallFifth}
                firstAlt={'Starfall'}
                secondImage={imageSmallSixth}
                secondAlt={'Starfall'}
            />
            <div className="starfall__video_type_margin">
                <video className="main-screen__video" autoPlay muted loop poster={imageBigFirst} playsInline>
                    <source src={videoSecond} type="video/mp4" />
                </video>
            </div>
            <CoupleImage
                margin={false}
                firstImage={imageSmallSeventh}
                firstAlt={'Starfall'}
                secondImage={imageSmallEighth}
                secondAlt={'Starfall'}
            />
            <CoupleImage
                margin={true}
                firstImage={imageSmallNinth}
                firstAlt={'Starfall'}
                secondImage={imageSmallTenth}
                secondAlt={'Starfall'}
            />
            <ImageBig
                padding={true}
                image={imageBigSecond}
                alt={'Starfall'}
            />
            <HeadingBig
                title={'website'}
            />
            <ImageMediumWithScroll
                margin={false}
                image={imageMediumThird}
                alt={'Starfall'}
            />
            <div className="starfall__video_type_margin starfall__video_type_margin-bottom">
                <video className="main-screen__video" autoPlay muted loop poster={imageBigThird} playsInline>
                    <source src={videoThird} type="video/mp4" />
                </video>
            </div>
            <NextCase
                history={props.history}
                link={'/projects/gami-trading'}
            />
        </div>
    );
}

export default Starfall;