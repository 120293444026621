function IntroductionWithVideo(props) {
    return (
        <div className="introduction">
            <div className="introduction__info">
                <h2 className="introduction__name">{props.name}</h2>
                <h1 className="introduction__title">{props.title}</h1>
            </div>
            <video className="main-screen__video" autoPlay muted loop poster={props.image} playsInline>
                <source src={props.video} type="video/mp4" />
            </video>
        </div>
    );
}

export default IntroductionWithVideo;