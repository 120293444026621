function Introduction(props) {
    return (
        <div className="introduction">
            <div className="introduction__info">
                <h2 className="introduction__name">{props.name}</h2>
                <h1 className="introduction__title">{props.title}</h1>
            </div>
            <img className="introduction__image" src={props.image} alt={props.alt}></img>
        </div>
    );
}

export default Introduction;