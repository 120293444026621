function Service(props) {
    function togglePlus() {
        const id = props.serviceId;
        const plus = document.getElementById(id).querySelector('.list-services__plus');
        const text = document.getElementById(id).querySelector('.list-services__service-text');

        plus.classList.toggle('list-services__plus_type_active');
        text.classList.toggle('list-services__service-text_type_active');
    }

    return (
        <div className="list-services__service" id={props.serviceId}>
            <input className="list-services__checkbox" type="checkbox" id={props.id} onClick={togglePlus}></input>
            <label className={`list-services__service-heading ${props.headingClass}`} htmlFor={props.htmlFor}>{props.heading}<div className="list-services__plus"></div></label>
            <p className={`list-services__service-text ${props.textClass}`}>{props.text}</p>
        </div>
    );
}

export default Service;