import Introduction from "../Introduction/Introduction";
import HeadingMedium from "../Heading/HeadingMedium";
import ImageMedium from "../Image/ImageMedium";
import CoupleImage from "../Image/CoupleImage";
import HeadingBig from "../Heading/HeadingBig";
import ImageWithText from "../Image/ImagesWithText";
import ImageBig from "../Image/ImageBig";
import NextCase from "../NextCase";
import imageSmallFirst from "../../../images/pleh-small-first.jpg";
import imageSmallSecond from "../../../images/pleh-small-second.jpg";
import imageSmallThird from "../../../images/pleh-small-third.jpg";
import imageSmallFourth from "../../../images/pleh-small-fourth.jpg";
import imageSmallFifth from "../../../images/pleh-small-fifth.jpg";
import imageSmallSixth from "../../../images/pleh-small-sixth.jpg";
import imageSmallSeventh from "../../../images/pleh-small-seventh.jpg";
import imageSmallEighth from "../../../images/pleh-small-eighth.jpg";
import imageSmallNinth from "../../../images/pleh-small-ninth.jpg";
import imageSmallTenth from "../../../images/pleh-small-tenth.jpg";
import imageSmallEleventh from "../../../images/pleh-small-eleventh.jpg";
import imageSmallTwelfth from "../../../images/pleh-small-twelfth.jpg";
import imageMediumFirst from "../../../images/client-pleh-medium-first.jpg";
import imageMediumSecond from "../../../images/pleh-medium-second.jpg";
import imageMediumThird from "../../../images/pleh-medium-third.jpg";
import imageMediumFourth from "../../../images/pleh-medium-fourth.jpg";
import imageBigFirst from "../../../images/introduction-pleh.jpg";
import imageBigSecond from "../../../images/pleh-big-second.jpg";
import imageBigThird from "../../../images/pleh-big-third.jpg";

function Pleh(props) {
    return (
        <div className="pleh">
            <Introduction
                name={'pleH'}
                title={'innovative educational platform powered by AI for students and teachers worldwide'}
                image={imageBigFirst}
                alt={'pleH'}
            />
            <HeadingMedium
                title={'The Client'}
                text={'Main feature at pleH is to equip students and teachers worldwide with tools to excel beyond the limitations of traditional classroom learning. By using the capabilities of AI, pleH are committed to building a future where high-quality, personalized education is accessible and affordable for all.'}
            />
            <ImageMedium
                padding={false}
                image={imageMediumFirst}
                alt={'pleH'}
            />
            <HeadingMedium
                title={'The Challenge'}
                text={'The goals of our agency included creating a logo and branding for the project, creating a color palette and selecting fonts, as well as rendering all the main screens of the user flow with full adaptability'}
            />
            <CoupleImage
                margin={false}
                firstImage={imageSmallFirst}
                firstAlt={'pleH'}
                secondImage={imageSmallSecond}
                secondAlt={'pleH'}
            />
            <CoupleImage
                margin={true}
                firstImage={imageSmallThird}
                firstAlt={'pleH'}
                secondImage={imageSmallFourth}
                secondAlt={'pleH'}
            />
            <HeadingBig
                title={'platform'}
            />
            <ImageMedium
                padding={false}
                image={imageMediumSecond}
                alt={'pleH'}
            />
            <HeadingMedium
                title={'Artificial Intelligence'}
                text={'Each registered user goes through a small onboarding, after which he sees a personalized panel. AI can help user to write an essay, solve a math or physics problem, and even write code.'}
            />
            <ImageWithText
                firstImage={imageSmallFifth}
                firstAlt={'pleH'}
                firstText={'Sign up'}
                secondImage={imageSmallSixth}
                secondAlt={'pleH'}
                secondText={'Writing assistant'}
                thirdImage={imageSmallSeventh}
                thirdAlt={'pleH'}
                thirdText={'Onboarding'}
                fourthImage={imageSmallEighth}
                fourthAlt={'pleH'}
                fourthText={'Math assistant'}
            />
            <ImageBig
                padding={true}
                image={imageBigSecond}
                alt={'pleH'}
            />
            <HeadingMedium
                title={'Built-in-editor'}
                text={'Any document created on the platform can be easily edited in the built-in editor and imported in a convenient format. Also, the platform has a separate section for files so that the user can quickly return to his work.'}
            />
            <ImageMedium
                padding={true}
                image={imageMediumThird}
                alt={'pleH'}
            />
            <ImageWithText
                firstImage={imageSmallNinth}
                firstAlt={'pleH'}
                firstText={'My Files - folders & documents'}
                secondImage={imageSmallTenth}
                secondAlt={'pleH'}
                secondText={'Coding answer'}
                thirdImage={imageSmallEleventh}
                thirdAlt={'pleH'}
                thirdText={'Colors'}
                fourthImage={imageSmallTwelfth}
                fourthAlt={'pleH'}
                fourthText={'Coding flow'}
            />
            <ImageBig
                padding={true}
                image={imageBigThird}
                alt={'pleH'}
            />
            <HeadingMedium
                title={'80 + Screens was done'}
                text={'Our team made also all additional screens such as settings, billing, password and email changes, my files, assistant, teacher’s flow and many others with full adaptability for various devices.'}
            />
            <ImageMedium
                padding={false}
                image={imageMediumFourth}
                alt={'pleH'}
            />
            <NextCase
                history={props.history}
                link={'/projects/dataspike'}
            />
        </div>
    );
}

export default Pleh;