function Footer(props) {
    function openBehance() {
        window.open('https://www.behance.net/makeitwow');
    }

    function openDribbble() {
        window.open('https://dribbble.com/makeitwow');
    }

    function openInstagram() {
        window.open('https://www.instagram.com/makeitwow.io/');
    }

    function openTelegram() {
        window.open('https://t.me/nikitina_alexa');
    }

    function openLinkedIn() {
        window.open('https://www.linkedin.com/company/make-it-wow/');
    }

    return (
        <div className="footer">
            <div className="footer__info">
                <div className="footer__contacts">
                    <button className="footer__email" onClick={props.openMail}>hi@makeitwow.io</button>
                    <div className="footer__main-info">
                        <p className="footer__main-info_type_text">Dubai Design District, Dubai, United Arab Emirates</p>
                        <p className="footer__main-info_type_text">+971 52 177 06 94</p>
                        <p className="footer__main-info_type_text">License №100058</p>
                    </div>
                </div>
                <div className="footer__links">
                    <div className="footer__social">
                        <button className="footer__social-icon" onClick={openBehance}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                                <path d="M21.6855 22.954C21.6855 22.954 23.9558 22.7815 23.9558 20.065C23.9558 17.3508 22.416 16.0128 20.0625 16.0128L12 16V31H20.25C20.25 31 24.375 31.1305 24.375 26.5C24.375 26.5 24.678 22.954 21.6855 22.954ZM15 18.25H19.125C19.125 18.25 20.5365 18.2733 20.5365 20.1033C20.5365 22 19.125 22 19.125 22H15V18.25ZM19.5 28.75H15V24.25H19.5C19.5 24.25 21.75 24.2733 21.75 26.5C21.75 28.7268 19.824 28.75 19.5 28.75Z"/>
                                <path d="M30.7502 19.75C25.1155 19.75 25.1252 25.375 25.1252 25.375C25.1252 25.375 24.7502 31 30.7502 31C30.7502 31 36.0003 31 36.0003 26.5H33.0002C33.0002 26.5 33.0002 28.75 30.7502 28.75C30.7502 28.75 28.5002 28.75 28.5002 25.75C28.5002 25.75 34.5003 25.75 36.0003 25.75C36.0003 24.25 36.0003 19.75 30.7502 19.75ZM28.5002 24.25C28.5002 24.25 28.4507 22 30.7502 22C33.049 22 33.0002 24.25 33.0002 24.25H28.5002Z"/>
                                <path d="M33.75 17.5H27.75V19H33.75V17.5Z"/>
                            </svg>
                        </button>
                        <button className="footer__social-icon" onClick={openDribbble}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M24 12C17.3753 12 12 17.3753 12 24C12 30.6247 17.3753 36 24 36C30.6117 36 36 30.6247 36 24C36 17.3753 30.6117 12 24 12ZM31.9262 17.5314C33.3579 19.2755 34.2169 21.5011 34.243 23.9089C33.9046 23.8438 30.5206 23.154 27.1106 23.5835C27.0325 23.4143 26.9675 23.2321 26.8894 23.0499C26.6811 22.5553 26.4468 22.0477 26.2126 21.5662C29.987 20.0304 31.705 17.8178 31.9262 17.5314ZM24 13.7701C26.603 13.7701 28.9848 14.7462 30.7939 16.3471C30.6117 16.6074 29.0629 18.6768 25.4187 20.0434C23.7397 16.9588 21.8785 14.4338 21.5922 14.0434C22.3601 13.8612 23.167 13.7701 24 13.7701ZM19.6399 14.7332C19.9132 15.0976 21.7354 17.6356 23.4403 20.6551C18.6508 21.9306 14.4208 21.9046 13.9653 21.9046C14.6291 18.7289 16.7766 16.0868 19.6399 14.7332ZM13.744 24.013C13.744 23.9089 13.744 23.8048 13.744 23.7006C14.1866 23.7137 19.1584 23.7787 24.2733 22.243C24.5727 22.8156 24.846 23.4013 25.1063 23.987C24.9761 24.026 24.833 24.0651 24.7028 24.1041C19.4187 25.8091 16.6074 30.4686 16.3731 30.859C14.7462 29.0499 13.744 26.6421 13.744 24.013ZM24 34.256C21.6312 34.256 19.4447 33.449 17.7137 32.0954C17.8959 31.718 19.9783 27.7093 25.757 25.692C25.7831 25.679 25.7961 25.679 25.8221 25.6659C27.2668 29.4013 27.8525 32.538 28.0087 33.436C26.7722 33.9696 25.4187 34.256 24 34.256ZM29.7137 32.4989C29.6095 31.8742 29.0629 28.8807 27.7224 25.1974C30.9371 24.6898 33.7484 25.5228 34.0998 25.6399C33.6573 28.4902 32.0173 30.9501 29.7137 32.4989Z"/>
                            </svg>
                        </button>
                        <button className="footer__social-icon" onClick={openInstagram}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                                <path d="M23.9982 17.7578C20.6111 17.7578 17.8047 20.5159 17.8047 23.9514C17.8047 27.3868 20.5628 30.1449 23.9982 30.1449C27.4337 30.1449 30.1918 27.3385 30.1918 23.9514C30.1918 20.5643 27.3853 17.7578 23.9982 17.7578ZM23.9982 27.9191C21.8208 27.9191 20.0305 26.1288 20.0305 23.9514C20.0305 21.7739 21.8208 19.9836 23.9982 19.9836C26.1757 19.9836 27.966 21.7739 27.966 23.9514C27.966 26.1288 26.1757 27.9191 23.9982 27.9191Z"/>
                                <path d="M30.4345 19.0174C31.2095 19.0174 31.8377 18.3891 31.8377 17.6142C31.8377 16.8392 31.2095 16.2109 30.4345 16.2109C29.6595 16.2109 29.0312 16.8392 29.0312 17.6142C29.0312 18.3891 29.6595 19.0174 30.4345 19.0174Z"/>
                                <path d="M34.0645 13.9839C32.8065 12.6774 31.0161 12 28.9839 12H19.0161C14.8065 12 12 14.8065 12 19.0161V28.9355C12 31.0161 12.6774 32.8065 14.0323 34.1129C15.3387 35.371 17.0806 36 19.0645 36H28.9355C31.0161 36 32.7581 35.3226 34.0161 34.1129C35.3226 32.8548 36 31.0645 36 28.9839V19.0161C36 16.9839 35.3226 15.2419 34.0645 13.9839ZM33.871 28.9839C33.871 30.4839 33.3387 31.6936 32.4677 32.5161C31.5968 33.3387 30.3871 33.7742 28.9355 33.7742H19.0645C17.6129 33.7742 16.4032 33.3387 15.5323 32.5161C14.6613 31.6452 14.2258 30.4355 14.2258 28.9355V19.0161C14.2258 17.5645 14.6613 16.3548 15.5323 15.4839C16.3548 14.6613 17.6129 14.2258 19.0645 14.2258H29.0323C30.4839 14.2258 31.6935 14.6613 32.5645 15.5323C33.3871 16.4032 33.871 17.6129 33.871 19.0161V28.9839Z"/>
                            </svg>
                        </button>
                        <button className="footer__social-icon" onClick={openTelegram}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                                <path d="M33.9627 15.4794L29.956 31.3902C29.7675 32.1395 28.8954 32.5256 28.1804 32.1697L23.1129 29.6492L20.7167 33.4263C20.0647 34.4558 18.4149 34.0092 18.4149 32.8055V28.5969C18.4149 28.2715 18.5562 27.9611 18.7998 27.734L28.6754 18.6508C28.6675 18.5373 28.5418 18.4389 28.4162 18.522L16.6318 26.4245L12.6723 24.4564C11.7453 23.9947 11.7846 22.7002 12.7429 22.2992L32.266 14.1017C33.2009 13.7081 34.2066 14.5181 33.9631 15.4793L33.9627 15.4794Z"/>
                            </svg>
                        </button>
                        <button className="footer__social-icon" onClick={openLinkedIn}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                                <path d="M35 26.8774V35H30.2646V27.3726C30.2646 25.4906 29.574 24.2028 27.8969 24.2028C26.6144 24.2028 25.8251 25.0943 25.5291 25.8868C25.4305 26.184 25.3318 26.5802 25.3318 27.0755V35H20.5964C20.5964 35 20.6951 22.1226 20.5964 20.8349H25.3318V22.816C25.9238 21.8255 27.1076 20.4387 29.574 20.4387C32.6323 20.4387 35 22.5189 35 26.8774ZM15.6637 14C14.0852 14 13 15.0896 13 16.4764C13 17.8632 13.9865 18.9528 15.565 18.9528C17.2422 18.9528 18.2287 17.8632 18.2287 16.4764C18.3274 14.9906 17.3408 14 15.6637 14ZM13.296 35H18.0314V20.8349H13.296V35Z" fill="white"/>
                            </svg>
                        </button>
                    </div>
                    <button className="footer__privacy-policy">Privacy Policy</button>
                </div>
            </div>
            <p className="footer__make-it-wow">Make it wow</p>
        </div>
    );
}

export default Footer;