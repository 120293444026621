import CoupleOfProjects from "../Projects/CoupleOfProjects";
import starfall from "../../images/starfall.jpg";
import gami from "../../images/gami.jpg";
import pleh from "../../images/pleh.jpg";
import dataspike from "../../images/dataspike.png";



function LatestProjects(props) {
    function openProjects() {
        props.history.push('/projects');
    }

    return (
        <div className="latest-projects">
            <div className="latest-projects__title-container">
                <h2 className="latest-projects__title">Latest projects</h2>
                <button className="latest-projects__link" onClick={openProjects}>
                    <p className="latest-projects__link_type_text">See all our projects</p>
                    <svg className="latest-projects__link_type_arrow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M9.29289 3.29289C9.68342 2.90237 10.3166 2.90237 10.7071 3.29289L14.7071 7.29289C15.0976 7.68342 15.0976 8.31658 14.7071 8.70711L10.7071 12.7071C10.3166 13.0976 9.68342 13.0976 9.29289 12.7071C8.90237 12.3166 8.90237 11.6834 9.29289 11.2929L11.5858 9H2C1.44772 9 1 8.55228 1 8C1 7.44772 1.44772 7 2 7H11.5858L9.29289 4.70711C8.90237 4.31658 8.90237 3.68342 9.29289 3.29289Z"/>
                    </svg>
                </button>
            </div>
                <CoupleOfProjects
                    history={props.history}
                    nameFirst={'Starfall'}
                    headingFirst={'New-generation Web3 Moba Game'}
                    imageFirst={starfall}
                    linkFirst={'/projects/starfall'}
                    nameSecond={'Gami Trading'}
                    headingSecond={'Trading Platform App: Desktop, Mobile Web, Android, iOS'}
                    imageSecond={gami}
                    linkSecond={'/projects/gami-trading'}
                />
                <CoupleOfProjects
                    history={props.history}
                    nameFirst={'pleH'}
                    headingFirst={'Innovative educational platform powered by AI for students and teachers worldwide'}
                    imageFirst={pleh}
                    linkFirst={'/projects/pleh'}
                    nameSecond={'Dataspike'}
                    headingSecond={'Anti-Money Laundering Compliance Scanner'}
                    imageSecond={dataspike}
                    linkSecond={'/projects/dataspike'}
                />
        </div>
    );
}

export default LatestProjects;